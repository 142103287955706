import React, { useState } from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import {
  IconButton,
  Drawer,
  DrawerHeader,
  DrawerBody,
  Typography
} from '@one-thd/sui-atomic-components';
import { Info } from '@one-thd/sui-icons';
import { getDrawerInfo } from '../../../utils/selectable-varient-utils';

export const InfoDrawer = ({ variantName }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerData = getDrawerInfo(variantName);
  if (!Object.keys(drawerData)?.length) {
    return null;
  }

  const { info, header: drawerHeader } = drawerData;

  return (
    <>
      <IconButton
        icon={Info}
        iconSize="small"
        buttonSize="tight"
        aria-label="info-button"
        onClick={() => {
          setDrawerOpen(true);
        }}
      />
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <DrawerHeader title={drawerHeader} onClose={() => setDrawerOpen(false)} />
        <DrawerBody>
          {info?.map(({ title, sections }, infoIndex) => {
            const isLastInfo = info?.length - 1 === infoIndex;
            const hasNoSections = sections?.length === 0;

            return (
              <div
                className={classnames('sui-flex sui-flex-col sui-mb-3', {
                  '!sui-mb-0': isLastInfo && hasNoSections
                })}
                key={`info- ${title}`}
              >
                {title && (
                  <div className={classnames('sui-mb-3', {
                    '!sui-mb-0': hasNoSections
                  })}
                  >
                    <Typography variant="h6" height="normal">
                      {title}
                    </Typography>
                  </div>
                )}
                {sections?.map((section, sectionIndex) => {
                  const { header = '', text = '', list = [] } = section;
                  const isLastSection = sections?.length - 1 === sectionIndex;
                  const sectionMargin = classnames('sui-mb-3', {
                    '!sui-mb-0': isLastInfo && isLastSection
                  });

                  return (
                    <>
                      {header && (
                        <div className={sectionMargin}>
                          <Typography variant="body-base" height="normal">
                            {header}
                          </Typography>
                        </div>
                      )}
                      {text && (
                        <div className={sectionMargin}>
                          <Typography variant="body-base" height="normal">
                            {text}
                          </Typography>
                        </div>
                      )}
                      {!!list?.length && (
                        <div className={sectionMargin}>
                          <ul className="sui-list-disc sui-pl-6" role="presentation">
                            {list?.map((listItem) => {
                              return (
                                <li key={`list- ${listItem}`}>
                                  <Typography variant="body-base" height="normal">
                                    {listItem}
                                  </Typography>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            );
          })}
        </DrawerBody>
      </Drawer>
    </>
  );
};

InfoDrawer.propTypes = {
  variantName: string
};

InfoDrawer.defaultProps = {
  variantName: null
};
