export const getProductDetails = (
  product, reviews, configuratorPricing, itemId, pricing, averageOverallRating, totalResults
) => {
  const brandLinkUrl = product?.taxonomy?.brandLinkUrl || '';
  const { ratingsReviews = {} } = reviews || {};
  const { averageRating, totalReviews } = ratingsReviews || {};
  const fallbackPricing = (configuratorPricing?.itemId && configuratorPricing?.itemId === itemId)
    ? configuratorPricing?.pricing : pricing;
  const { value } = fallbackPricing || {};
  const image = product?.media?.image;
  const reviewCount = typeof totalResults === 'number' ? totalResults : totalReviews || 0;
  const avgRating = reviewCount === 0 ? 0
    : averageOverallRating || averageRating || 0;
  const hasAverageRating = (avgRating === null) ? 0 : parseFloat(avgRating);

  return {
    brandLinkUrl, value, image, reviewCount, hasAverageRating
  };
};

export const getProductPricing = (product) => {
  const validPricing = product?.pricing && product?.pricing?.value > 0;
  return validPricing ? product : null;
};