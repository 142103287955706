import { useState, useEffect, useContext, useRef } from 'react';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';

import {
  filterRemovedProducts,
  getPriceAdjustment,
  onLifeCycleEvent,
  triggerLifeCycleEvent
} from '../utils/product-bundle-utils';
import { PRODUCTS_CHANGED } from '../constants/events';

let shouldUpdatePriceAdjustment = true;

const usePriceAdjustment = (products, loading) => {
  const { isLocalized, storeId } = useStore();
  const experienceContext = useContext(ExperienceContext);
  const { hosts } = experienceContext;
  const { globalCustomConfiguratorsBaseUrl: priceAdjustmentUrl } = hosts;

  const productsRef = useRef(null);
  const [adjustedPricing, setAdjustedPricing] = useState(null);
  const [areProductsUpdated, setAreProductsUpdated] = useState(false);
  const isProductDataReady = products?.length > 0 && isLocalized && !loading;

  const updatePriceAdjustment = async (productsData) => {
    const adjustedPrice = await getPriceAdjustment({
      storeId,
      products: productsData,
      url: priceAdjustmentUrl
    });
    triggerLifeCycleEvent('bundle.adjusted-price', adjustedPrice);
  };

  useEffect(() => {
    onLifeCycleEvent('bundle.adjusted-price', ({ output }) => {
      setAdjustedPricing(output);
    });
  }, []);

  useEffect(() => {
    onLifeCycleEvent(PRODUCTS_CHANGED, ({ output }) => {
      shouldUpdatePriceAdjustment = true;
      setAreProductsUpdated(true);
      productsRef.current = filterRemovedProducts(output);
    });
  }, []);

  useEffect(() => {
    const triggerPriceAdjustment = (isProductDataReady || areProductsUpdated) && shouldUpdatePriceAdjustment;
    if (triggerPriceAdjustment) {
      shouldUpdatePriceAdjustment = false;
      updatePriceAdjustment(productsRef.current || products);
      setAreProductsUpdated(false);
    }
  }, [isProductDataReady, areProductsUpdated]);

  return adjustedPricing;
};

export default usePriceAdjustment;
