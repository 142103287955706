import React, { useMemo } from 'react';

import { useConfigService } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';

import { getMADeliveryFee } from '../utils/product-bundle-utils';

const useDeliveryData = (products, calculateDeliveryFee) => {
  const { isExchangeCustomer } = useThdCustomer() || {};
  const enableApplianceDeliveryCharge = useConfigService('enableApplianceDeliveryCharge') || false;
  const applianceDeliveryThreshold = useConfigService('applianceDeliveryThreshold') ?? 396;
  const enableFreeDeliveryForExchange = useConfigService('enableFreeDeliveryForExchange') || false;
  const applianceDeliveryChargeValue = useConfigService('applianceDeliveryChargeValue') || 29;

  const deliveryData = useMemo(() => {
    if (!!products?.length && calculateDeliveryFee) {
      return getMADeliveryFee(
        products,
        enableApplianceDeliveryCharge,
        enableFreeDeliveryForExchange,
        applianceDeliveryChargeValue,
        isExchangeCustomer,
        applianceDeliveryThreshold
      );
    }
    return { deliveryFee: 0, freeShipping: false };

  }, [products, calculateDeliveryFee]);

  return deliveryData;
};

export default useDeliveryData;
