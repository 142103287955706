import React, { useState, Suspense, useContext } from 'react';
import PropTypes from 'prop-types';
import { QueryContext } from '@thd-nucleus/data-sources';
import { Skeleton } from '@one-thd/sui-atomic-components';
import { Price } from '@thd-olt-component-react/price';
import { CustomSkeletonLine } from './CustomSkeletonLine';
import { EmptyContainer } from './EmptyContainer';
import { RemoveDrawer } from './RemoveDrawer';
import { SwapToaster } from './SwapToaster';
import { SwapProductLoader } from './SwapProductLoader';
import { getIsMobile, tiggerAnalyticsCustomEvent, isFromBundleMini } from '../../utils/product-bundle-utils';
import { QuickViewLazy } from './QuickViewLazy';
import '../../styles/productListContent.scss';
import { ProductListImage } from './ProductListImage';
import { ProductListDetails } from './ProductListDetails';
import { SwapOptions } from './SwapOptions';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {{bundleSpecificationDetails: {type: string}}} product Bundle product
 * @property {array} products - The number of products in the bundle
 * @property {string} channel - If it is Desktop or Mobile
 * @typedef {object} accordionProps
 *  * @property {string} productSpecificationAccordionName Specification Accordion name to open and
 * close from parent accordion group
 *  * @property {number} scrollStickyHeaderHeight Height used to offset the scrolling action
 *  * @property {string} productDetailsAccordionName Product Details Accordion name to open and
 * close from parent accordion group
 */

/**
 * Returns a formatted Product List of the provided products.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const ProductListContent = ({
  products,
  product: bundleProduct,
  channel,
  addProduct,
  removeProduct,
  swapProducts,
  swapBack,
  clearSwap,
  loading,
  accordionProps
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const isMobile = getIsMobile(channel);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const { swapToaster } = bundleProduct?.features ?? {};

  const triggerViewFullDetailsAnalytics = isFromBundleMini();
  const { defaultVariables } = useContext(QueryContext) || {};
  const openQuickView = (product) => {
    if (!isQuickViewOpen) {
      setIsQuickViewOpen(true);
      setSelectedProduct(product);
    }
  };

  const triggerAnalytics = (index) => {
    const analyticsData = {
      position: `${index + 1}`
    };
    tiggerAnalyticsCustomEvent('remove-item.success', analyticsData);
  };

  return (
    <ul data-testid="product-list-content" className="sui-w-full">
      {products?.length > 0 ? (
        <>
          {products.map((product, index) => {
            const selectableVariants = bundleProduct?.productComponent?.[index]?.selectableVariants;

            return (
              <React.Fragment key={product?.itemId || index}>
                <li
                  key={product?.itemId}
                  className="product-list-item sui-flex sui-flex-col sui-gap-4"
                  data-testid="product-list-item"
                >
                  {product?.removed || product?.isDiscontinued ? (
                    <EmptyContainer
                      product={product}
                      loading={loading && selectedItemId === product.itemId}
                      onClick={addProduct}
                      setSelectedItemId={setSelectedItemId}
                      isMobile={isMobile}
                    />
                  ) : (
                    <React.Fragment key={`product-content-${product.itemId || index}`}>
                      {swapToaster && !product?.isVariantSwap && product?.swappedProduct && (
                        <SwapToaster
                          itemId={product?.itemId}
                          swapBack={swapBack}
                          clearSwap={clearSwap}
                        />
                      )}
                      <div className="sui-w-full">
                        {loading && selectedItemId === product.itemId ? (
                          <SwapProductLoader key={`removed-${product.itemId}`} />
                        ) : (
                          <div className="sui-flex sui-gap-4" key={`product-${product.itemId}`}>
                            <ProductListImage product={product} openQuickView={openQuickView} />
                            {/* eslint-disable tailwindcss/no-arbitrary-value */}
                            {/* eslint-disable-next-line max-len */}
                            <div className="sui-flex sui-flex-col sui-gap-2 sui-flex-1 md:sui-grid md:sui-grid-cols-[1fr_160px] md:sui-gap-4">
                              <ProductListDetails
                                product={product}
                                openQuickView={openQuickView}
                                channel={channel}
                                accordionProps={accordionProps}
                                setIsRatingOpen={setIsRatingOpen}
                                isMobile={isMobile}
                                selectableVariants={selectableVariants}
                                templates={bundleProduct?.features?.templates}
                                swapProducts={swapProducts}
                              />
                              <Price
                                data-testid="product-list-item-price"
                                channel={channel}
                                itemId={product?.itemId}
                                large={false}
                                hideLimitPerOrder
                                displayEachUom={false}
                                clsRemediation={{
                                  placeholders: true,
                                  preservePlaceholders: true
                                }}
                                product={{
                                  itemId: product.itemId,
                                  identifiers: { itemId: product.itemId },
                                  pricing: {
                                    ...product?.pricing
                                  }
                                }}
                                stackWasPrice
                              />
                              <div className="sui-flex sui-flex-col sui-gap-2 sui-w-full md:sui-gap-4">
                                <SwapOptions
                                  product={product}
                                  setSelectedItemId={setSelectedItemId}
                                  swapProducts={swapProducts}
                                  index={index}
                                  triggerAnalytics={triggerAnalytics}
                                  setIsDrawerOpen={setIsDrawerOpen}
                                  isDrawerOpen={isDrawerOpen}
                                  isMobile={isMobile}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </li>
                <hr
                  key={`separater-${product?.itemId || index}`}
                  className="sui-my-4 sui-w-full sui-h-0 sui-border-primary last-of-type:sui-hidden"
                />
              </React.Fragment>
            );
          })}
          {typeof window !== 'undefined' && isQuickViewOpen && (
            <Suspense fallback={<div />}>
              <QuickViewLazy
                isOpen={isQuickViewOpen}
                onClose={() => {
                  setIsQuickViewOpen(false);
                  setSelectedProduct(null);
                  setIsRatingOpen(false);
                }}
                fetch={isQuickViewOpen}
                itemId={selectedProduct?.itemId}
                isRatingsOpen={isRatingOpen}
                paginateCarousel={false}
                openDetailsInNewTab
                triggerViewFullDetailsAnalytics={triggerViewFullDetailsAnalytics}
                defaultVariables={defaultVariables}
              />
            </Suspense>
          )}
        </>
      ) : (
        [...Array(5).keys()].map((item) => (
          <li
            key={item}
            // eslint-disable-next-line max-len
            className="product-list-item first:sui-pt-0 last:sui-pb-0 last:sui-border-0 sui-flex sui-items-start sui-border-b-1 sui-border-primary sui-border-solid sui-py-4"
            data-testid="product-list-item"
          >
            <div className="sui-w-full" data-testid="skeleton-loader">
              <Skeleton grow disableGutters disablePadding>
                <CustomSkeletonLine height="80px" />
              </Skeleton>
            </div>
          </li>
        ))
      )}
      {isDrawerOpen && (
        <RemoveDrawer
          closeDrawer={() => setIsDrawerOpen(false)}
          itemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
          isDrawerOpen={isDrawerOpen}
          storeId="121"
          products={products}
          removeProduct={removeProduct}
          swapProducts={swapProducts}
          loading={loading}
          isMobile={isMobile}
        />
      )}
    </ul>
  );
};

ProductListContent.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      dataSource: PropTypes.string
    })
  ),
  product: PropTypes.shape({
    bundleSpecificationDetails: PropTypes.shape({
      type: PropTypes.string
    }),
    features: PropTypes.shape({
      swapToaster: PropTypes.bool,
      templates: PropTypes.shape({
        [PropTypes.string]: PropTypes.string
      })
    }),
    productComponent: PropTypes.arrayOf(
      PropTypes.shape({
        selectableVariants: PropTypes.shape({
          [PropTypes.string]: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired
            })
          )
        })
      })
    )
  }),
  channel: PropTypes.string.isRequired,
  accordionProps: PropTypes.shape({
    productSpecificationAccordionName: PropTypes.string,
    scrollStickyHeaderHeight: PropTypes.number,
    productDetailsAccordionName: PropTypes.string
  }).isRequired,
  addProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  swapProducts: PropTypes.func.isRequired,
  swapBack: PropTypes.func.isRequired,
  clearSwap: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

ProductListContent.defaultProps = {
  products: undefined,
  product: undefined
};

ProductListContent.displayName = 'ProductListContent';

export { ProductListContent };
