/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { string as propTypeString, bool as propTypeBool } from 'prop-types';
import {
  params, string, arrayOf, extend, shape, bool, number, QueryContext
} from '@thd-nucleus/data-sources';

import { useBundleProducts } from '../hooks/useBundleProducts';
import { useBundlePricingDetails } from '../hooks/useBundlePricingDetails';

const dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      itemId: string(),
      bundleSpecificationDetails: shape({
        type: string(),
        brand: string(),
        experienceType: string(),
        components: arrayOf(shape({
          id: string(),
          defaultProductId: string(),
          quantity: string(),
          type: string(),
          allowSwap: bool(),
          allowRemove: bool(),
          selectableVariants: arrayOf(shape({
            name: string(),
            id: string(),
            guid: string(),
            value: string()
          })),
          priority: number()
        }))
      })
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      itemId: string(),
      identifiers: shape({
        brandName: string(),
        productLabel: string(),
        productType: string()
      })
    })
  }
);

export const withBundleProducts = (BaseComponent) => {

  const BundleComponent = ({ bundleId, storeId, listenToProductsChange, ...rest }) => {
    const { defaultVariables } = useContext(QueryContext) || {};
    const isCustomerIdentified = defaultVariables?.current?.isBrandPricingPolicyCompliant || false;
    const bundleProducts = useBundleProducts(bundleId, storeId, listenToProductsChange, isCustomerIdentified);
    const pricingDetails = useBundlePricingDetails({ ...bundleProducts, isCustomerIdentified });
    return (
      <BaseComponent
        {...bundleProducts}
        {...rest}
        pricingDetails={pricingDetails}
      />
    );
  };

  BundleComponent.dataModel = BaseComponent?.dataModel
    ? extend(dataModel, BaseComponent?.dataModel)
    : dataModel;

  BundleComponent.propTypes = {
    bundleId: propTypeString.isRequired,
    storeId: propTypeString.isRequired,
    listenToProductsChange: propTypeBool
  };

  BundleComponent.defaultProps = {
    listenToProductsChange: true
  };

  return BundleComponent;
};