import React from 'react';
import { Typography, Link, RatingMeter } from '@one-thd/sui-atomic-components';
import {
  shape as shapeProp,
  number as propTypeNumber,
  string as propTypeString,
  func as propTypeFunc,
  bool as propTypeBool
} from 'prop-types';

import {
  getSelectableVarientType,
  getVariantGroup,
  isProductInVariantGroup
} from '../../utils/selectable-varient-utils';
import { KITCHEN_FUEL_TYPE } from '../../constants/index';
import { InfoDrawer } from './infoDrawer/InfoDrawer';
import { onClickProductAccordionLink, accordionToggle } from '../../utils/product-bundle-utils';
import { BundleTemplateLayout } from './BundleTemplateLayout';

export const ProductListDetails = (props) => {
  const {
    product,
    openQuickView,
    isMobile,
    accordionProps,
    setIsRatingOpen,
    selectableVariants,
    swapProducts,
    templates
  } = props;
  const {
    quickView,
    productListFuelType,
    productListModelNumber,
    productListRating,
    productListViewSpecificationLink,
    showVarientDrawerInfoIcon,
    productLabelScrollableLink,
    brandNameScrollableLink
  } = product?.features ?? {};

  const { productSpecificationAccordionName, scrollStickyHeaderHeight, productDetailsAccordionName } = accordionProps;
  const fuelType = productListFuelType && getSelectableVarientType(product, KITCHEN_FUEL_TYPE);
  const { variantName, variants } = getVariantGroup(selectableVariants);
  const isInVariantGroup = isProductInVariantGroup({ productId: product?.itemId, variants });

  const showInfoDrawer = showVarientDrawerInfoIcon && variantName && isInVariantGroup;

  return (
    <div className="sui-w-full">
      {
        quickView && (
          <>
            <div className="sui-flex sui-gap-x-1">
              <Link
                variant="body-base"
                underline="hover"
                onClick={() => openQuickView(product)}
                data-testid="product-list-product-label"
              >
                <b>{product?.identifiers?.brandName}</b>
              </Link>
              {showInfoDrawer && <InfoDrawer variantName={variantName} />}
            </div>
            <Link
              variant="body-base"
              underline="hover"
              onClick={() => openQuickView(product)}
              data-testid="product-list-product-label"
            >
              {product?.identifiers?.productLabel}
            </Link>
          </>
        )
      }
      {brandNameScrollableLink && (
        <Link
          href={productDetailsAccordionName}
          rel="noopener noreferrer"
          underline="hover"
          onClick={(event) => {
            onClickProductAccordionLink({
              event,
              product,
              accordionName: productDetailsAccordionName,
              isMobile,
              scrollStickyHeaderHeight
            });
            accordionToggle({ accordionName: productSpecificationAccordionName });
          }}
        >
          <Typography variant="body-base" weight="bold" data-testid="product-list-brand-label">
            <div className="sui-flex sui-gap-x-1">
              {product?.identifiers?.brandName}
              {showInfoDrawer && <InfoDrawer variantName={variantName} />}
            </div>
          </Typography>
        </Link>
      ) }
      {
        productLabelScrollableLink && (
          <Link
            href={productDetailsAccordionName}
            rel="noopener noreferrer"
            underline="hover"
            onClick={(event) => {
              onClickProductAccordionLink({
                event,
                product,
                accordionName: productDetailsAccordionName,
                isMobile,
                scrollStickyHeaderHeight
              });
              accordionToggle({ accordionName: productSpecificationAccordionName });
            }}
          >
            <Typography
              lineClamp="2"
              variant="body-base"
              data-testid="product-list-product-label"
            >
              {product?.identifiers?.productLabel}
            </Typography>
          </Link>
        )
      }
      {
        product?.quantity > 1 && (
          <Typography variant="body-base" data-testid="product-list-quantity">
            Qty: {product?.quantity}
          </Typography>
        )
      }
      {
        productListModelNumber && product?.identifiers?.modelNumber && (
          <Typography variant="body-xs" color="subtle">
            Model # {product.identifiers.modelNumber}
          </Typography>
        )
      }
      {
        fuelType && (
          <Typography variant="body-xs">
            <p className="sui-text-base sui-pt-3"> Fuel Type -
              <span className="sui-font-bold sui-pl-1">
                {fuelType}
              </span>
            </p>
          </Typography>
        )
      }
      {
        productListRating && product?.reviews?.ratingsReviews && (
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          <div className="sui-flex sui-pt-3 [&>*]:sui-gap-1">
            <RatingMeter
              data-testid="product-list-item-rating-reviews"
              className="hover:sui-cursor-pointer"
              value={parseFloat(product?.reviews?.ratingsReviews?.averageRating)}
              label={`(${product?.reviews?.ratingsReviews?.totalReviews})`}
              target="_blank"
              edge="start"
              RatingProps={{
                color: 'brand'
              }}
              onClick={() => {
                setIsRatingOpen(true);
                openQuickView(product);
              }}
            />
          </div>
        )
      }
      <BundleTemplateLayout
        selectableVariants={selectableVariants}
        templates={templates}
        product={product}
        onChange={swapProducts}
      />
      {
        productListViewSpecificationLink && (
          <div className="sui-pt-3">
            <Link
              href={productSpecificationAccordionName}
              underline="always"
              rel="noopener noreferrer"
              onClick={(event) => onClickProductAccordionLink({
                event,
                product,
                accordionName: productSpecificationAccordionName,
                isMobile,
                scrollStickyHeaderHeight
              })}
            >
              View Item Specifications
            </Link>
          </div>
        )
      }
    </div>
  );
};

ProductListDetails.propTypes = {
  product: shapeProp({}),
  openQuickView: propTypeFunc.isRequired,
  isMobile: propTypeBool,
  accordionProps: shapeProp({
    productSpecificationAccordionName: propTypeString,
    scrollStickyHeaderHeight: propTypeNumber,
    productDetailsAccordionName: propTypeString
  }).isRequired,
  setIsRatingOpen: propTypeFunc.isRequired,
  selectableVariants: shapeProp({}),
  swapProducts: propTypeFunc.isRequired,
  templates: shapeProp({
    [propTypeString]: propTypeString
  }).isRequired
};

ProductListDetails.defaultProps = {
  product: null,
  isMobile: false,
  selectableVariants: null
};