/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  arrayOf as propTypeArrayOf,
  shape as propTypeShape,
  string as propTypeString,
  bool as propTypeBool,
  number as propTypeNumber
} from 'prop-types';

import {
  params, string, arrayOf, number, bool, shape, extend
} from '@thd-nucleus/data-sources';
import { SignInLink } from '@thd-olt-component-react/price';
import { Typography, Alert, Tooltip } from '@one-thd/sui-atomic-components';

import {
  formatPrice,
  getBundleQuantity
} from '../utils/product-bundle-utils';
import PriceContainer from './subcomponents/PriceContainer';
import { AdditionalSavingsContent } from './subcomponents/AdditionalSavingsContent';
import { MajorApplianceDeliveryAndTaxContent } from './subcomponents/MajorApplianceDeliveryAndTaxContent';
import { TOOLTIP_VIEW_DETAILS, MIN_ADVERTISED_PRICE_MESSAGE, MIN_ADVERTISED_PRICE_MESSAGE_SIGN_IN } from '../constants';

/**
 * Component that displays a summary of a product bundle, including pricing, savings, delivery, and tax details.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.product - The main product associated with the bundle.
 * @param {Object[]} props.products - The list of products included in the bundle.
 * @param {string} props.itemId - The unique identifier for the item.
 * @param {Object} props.pricingDetails - The pricing details for the bundle.
 * @param {Object} [props.pricingDetails.pricing] - The pricing information for the bundle.
 * @param {boolean} [props.pricingDetails.pricing.loading] - Indicates if the pricing data is still loading.
 * @param {number} [props.pricingDetails.pricing.value] - The final price of the bundle.
 * @param {number} [props.pricingDetails.pricing.original] - The original price of the bundle before discounts.
 * @param {Object} [props.pricingDetails.delivery] - The delivery details for the bundle.
 * @param {Object} [props.pricingDetails.adjustments] - The adjustments applied to the bundle pricing.
 * @param {number} [props.pricingDetails.adjustments.bundleItemSavings] - The savings from individual items in the bundle.
 * @param {number} [props.pricingDetails.adjustments.bundleSavings] - The total savings for the bundle.
 * @param {Object} [props.pricingDetails.mapDetail] - The Minimum Advertised Price (MAP) details.
 * @param {number} [props.pricingDetails.mapDetail.minAdvertisedPrice] - The minimum advertised price for the bundle.
 * @param {boolean} [props.pricingDetails.mapDetail.showMapInfo] - Indicates if MAP information should be displayed.
 * @param {boolean} [props.pricingDetails.mapDetail.showSignInMapAlert] - Indicates if a sign-in alert for MAP should be displayed.
 * @param {Object} [props.pricingDetails.calculations] - The calculated pricing details.
 * @param {number} [props.pricingDetails.calculations.subTotal] - The subtotal price of the bundle.
 * @param {number} [props.pricingDetails.calculations.finalDiscountedPrice] - The final discounted price of the bundle.
 *
 * @returns {JSX.Element} The rendered BundleSummary component.
 */
const BundleSummary = ({ product, products, itemId, pricingDetails }) => {
  const {
    pricing,
    delivery,
    adjustments,
    mapDetail,
    calculations
  } = pricingDetails || {};
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundle-summary.ready');
  }, []);

  const {
    bundleSummaryTitle, deliveryAndTax, summaryRegularPriceType, showBundleSavings
  } = product?.features ?? {};

  const savingsDetail = 'The amount you save from individual items in this bundle';

  const bundleQuantity = getBundleQuantity(products);

  const { minAdvertisedPrice, showMapInfo, showSignInMapAlert } = mapDetail ?? {};

  const showMapAlert = showMapInfo && minAdvertisedPrice;

  if (pricingDetails?.loading) {
    return null;
  }
  const suiRowClasses = 'sui-flex sui-flex-row sui-justify-between sui-mt-1';
  const dashClass = 'sui-w-3 sui-bg-primary sui-border-solid sui-border-strongest sui-border-b-1 sui-mr-5 sui-ml-auto sui-h-4';
  const finalPrice = formatPrice(pricing?.value);

  const { subTotal, finalDiscountedPrice } = calculations || {};

  return (
    <div
      className="sui-flex sui-flex-col sm:sui-mx-4"
      data-component="BundleSummary"
      data-testid="bundle-summary"
    >
      <Typography variant="body-lg" weight="bold">{bundleSummaryTitle}</Typography>
      <div>
        <div className={suiRowClasses}>
          <Typography variant="body-base">Regular Price ({bundleQuantity} {summaryRegularPriceType})</Typography>
          <PriceContainer minAdvertisedPrice={showMapAlert} price={!!pricing?.original} priceType="regular-price">
            <Typography variant="body-base" data-testid="retail-price">
              {formatPrice(pricing?.original)}
            </Typography>
          </PriceContainer>
        </div>

        {adjustments?.bundleItemSavings > 0 ? (
          <div className={suiRowClasses}>
            <span className="sui-flex sui-gap-1 sui-items-baseline">
              <span className="sui-flex sui-gap-1 sui-items-center">
                <Typography variant="body-base" color="success">Item Savings</Typography>
              </span>
              {!showMapAlert
                && (
                  <Tooltip offset="normal" title={savingsDetail} data-testid="bundle-savings-tooltip">
                    <Typography
                      variant="body-xs"
                      decoration="underline"
                      data-testid="bundle-view-details"
                    >
                      {TOOLTIP_VIEW_DETAILS}
                    </Typography>
                  </Tooltip>
                )}
            </span>
            <PriceContainer minAdvertisedPrice={showMapAlert} price={!!adjustments?.bundleItemSavings} priceType="product-savings">
              <Typography variant="body-base" color="success" data-testid="product-savings">
                -{formatPrice(adjustments?.bundleItemSavings)}
              </Typography>
            </PriceContainer>
          </div>
        ) : null}

        <div className="sui-flex sui-flex-row sui-justify-between sui-mt-2">
          <Typography variant="body-base">Subtotal</Typography>
          <PriceContainer minAdvertisedPrice={showMapAlert} price={!!subTotal} priceType="subtotal">
            <Typography variant="body-base" data-testid="subtotal">{formatPrice(subTotal)}</Typography>
          </PriceContainer>
        </div>
        <div className="sui-flex sui-w-full sui-justify-end sui-items-center sui-h-4">
          <span className="sui-border-solid sui-border-b-1 sui-mt-3 sui-w-20" />
        </div>
      </div>

      {adjustments?.bundleSavings > 0 ? (
        <AdditionalSavingsContent priceAdjustment={adjustments} showBundleSavings={showBundleSavings} />
      ) : null}

      {deliveryAndTax ? (
        <MajorApplianceDeliveryAndTaxContent deliveryData={delivery} />
      ) : (
        <div className={`${suiRowClasses} sui-items-center`}>
          <Typography variant="body-base">Delivery & Taxes calculated at checkout</Typography>
          <span className="sui-flex sui-flex-col sui-items-center sui-mb-2">
            <span className={dashClass} data-testid="delivery-taxes-dash" />
          </span>
        </div>
      )}
      <div className={`${suiRowClasses} sui-mt-5 sui-border-solid sui-border-t-1 sui-border-button-inactive sui-py-4`}>
        <Typography variant="body-lg" weight="bold">Total</Typography>
        <PriceContainer minAdvertisedPrice={showMapAlert} price={!!finalPrice} priceType="final-price">
          <span className="sui-text-right">
            <Typography variant="h3" component="p" weight="bold" data-testid="finalDiscountedPrice">{finalPrice}</Typography>
            {pricing?.original > finalDiscountedPrice && (
              <>
                <span className="sui-line-through">
                  <Typography variant="body-base" data-testid="retailPrice">
                    {formatPrice(pricing?.original)}
                  </Typography>
                </span>
                <Typography variant="body-base" color="success" data-testid="bundle-total-dollar-off">
                  Save {formatPrice(pricing?.promotion?.dollarOff)} <span>({Math.round(pricing?.promotion?.percentageOff)}%)</span>
                </Typography>
              </>
            )}
          </span>
        </PriceContainer>
      </div>
      {
        showMapAlert && showSignInMapAlert && (
          <Alert status="info">
            <Typography variant="body-base">{MIN_ADVERTISED_PRICE_MESSAGE_SIGN_IN}</Typography>
            <SignInLink itemId={itemId} layoutType="detailed" displayDarkTheme />
          </Alert>
        )
      }
      {
        showMapAlert && !showSignInMapAlert && (<Alert status="info">{MIN_ADVERTISED_PRICE_MESSAGE}</Alert>)
      }
    </div>
  );
};

BundleSummary.propTypes = {
  products: propTypeArrayOf(propTypeShape({
    pricing: propTypeShape(),
    bundleSpecificationDetails: propTypeShape({
      components: propTypeArrayOf(propTypeShape({
        quantity: propTypeNumber
      }))
    })
  })).isRequired,
  itemId: propTypeString.isRequired,
  product: propTypeShape({
    features: propTypeShape({
      bundleSummaryTitle: propTypeString.isRequired,
      priceUnitOfMeasure: propTypeString.isRequired
    })
  }).isRequired,
  pricingDetails: propTypeShape({
    loading: propTypeBool,
    pricing: propTypeShape({
      pricing: propTypeShape({
        original: propTypeNumber.isRequired,
        value: propTypeNumber.isRequired,
        promotion: propTypeShape({
          dollarOff: propTypeNumber.isRequired,
          percentageOff: propTypeNumber.isRequired
        })
      })
    }),
    mapDetail: propTypeShape({
      showMapInfo: propTypeBool.isRequired,
      minAdvertisedPrice: propTypeBool,
      showSignInMapAlert: propTypeBool
    }),
    delivery: propTypeShape({
      deliveryFee: propTypeNumber,
      freeShipping: propTypeBool
    }),
    adjustments: propTypeShape({
      bundleItemSavings: propTypeNumber,
      bundleSavings: propTypeNumber
    }),
    calculations: propTypeShape({
      subtotal: propTypeNumber,
      finalDiscountedPrice: propTypeNumber
    })
  })
};

BundleSummary.defaultProps = {
  pricingDetails: null
};

BundleSummary.displayName = 'BundleSummary';

BundleSummary.dataModel = extend(
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      pricing: params({ storeId: string(), isBrandPricingPolicyCompliant: bool() }).shape({
        original: number({ float: true }),
        mapAboveOriginalPrice: bool(),
        mapDetail: shape({
          mapPolicy: string(),
          mapOriginalPriceViolation: bool(),
          mapSpecialPriceViolation: bool()
        }),
        promotion: shape({
          type: string(),
          description: shape({
            shortDesc: string(),
            longDesc: string()
          }),
          dollarOff: number({ float: true }),
          percentageOff: number({ float: true })
        }),
        specialBuy: number({ float: true }),
        value: number({ float: true })
      }),
      identifiers: shape({
        brandName: string(),
        storeSkuNumber: string(),
        productLabel: string(),
        omsThdSku: string(),
        modelNumber: string(),
        canonicalUrl: string()
      })
    })
  }
);

export { BundleSummary };
