import { ITEM_PROMO, ORDER_PROMO } from '../constants';

export const getUniquePromoIds = (promos) => {
  const promoIds = promos?.filter((promo) => !!promo.promoId).map((promo) => promo.promoId) || [];
  return [...new Set(promoIds)];
};

export const getSavingLabels = (promos) => {
  const promoIds = getUniquePromoIds(promos);
  return promoIds.map((id) => promos.find((promo) => promo.promoId === id).description?.shortDesc || ''
  ) || [];
};

const getSavings = (promos) => {
  return promos?.map((promotion) => promotion.dollarOff)
    .reduce((savings, dollarOff) => savings + Number.parseFloat(dollarOff), 0) || 0;
};

export const parsePriceValue = (price) => {
  if (!price) {
    return null;
  }
  const priceParts = {
    dollars: 0,
    cents: 0
  };
  const priceSplit = price.toString().split('.');
  priceParts.dollars = priceSplit[0];
  priceParts.cents = priceSplit[1] || '00';
  if (priceParts.cents.length === 1) {
    priceParts.cents += '0';
  }
  return priceParts;
};

const calculatePromotionalAdjustments = (ids, products) => {

  const promotionalAdjustments = Array.from(products.values())
    .filter((product) => ids.includes(product.identifiers?.itemId) && !!product.pricing?.promotionalAdjustments)
    .flatMap((product) => product.pricing.promotionalAdjustments);

  const filteredPromos = promotionalAdjustments?.filter((promo) => {
    return (!!promo?.dollarOff && !Number.isNaN(promo.dollarOff));
  });

  const bundlePromos = filteredPromos?.filter((promotion) => ITEM_PROMO === promotion.type);
  const additionalPromos = filteredPromos?.filter((promotion) => ORDER_PROMO === promotion.type);
  const bundleSavings = getSavings(bundlePromos);
  const additionalSavings = getSavings(additionalPromos);

  return {
    bundleSavings,
    additionalSavings,
    hasBundleSavings: bundleSavings > 0,
    hasAdditionalSavings: additionalSavings > 0,
    bundleSavingsLabels: bundleSavings > 0 ? getSavingLabels(bundlePromos) : [],
    additionalSavingsLabels: additionalSavings > 0 ? getSavingLabels(additionalPromos) : []
  };
};

export const calculateTotal = (products = []) => {
  const template = {
    showMapMessage: false,
    original: 0,
    savings: 0,
    bundleSavings: 0,
    additionalSavings: 0,
    percentage: 0,
    value: 0
  };

  let calculatedTotal = products.reduce((total, item) => {
    const { pricing } = item;
    if (!pricing) return total;
    /* eslint-disable */
    total.original += pricing.original || pricing.value || 0;
    total.value += pricing.value || 0;
    total.savings += pricing.promotion?.dollarOff || 0;

    if (pricing.mapAboveOriginalPrice !== null) {
      total.showMapMessage = true;
    }
    /* eslint-enable */
    return total;
  }, template);

  const itemIds = products.map((product) => product.itemId);
  const adjustments = calculatePromotionalAdjustments(itemIds, products);
  const totalValue = Math.max(calculatedTotal.value - adjustments.bundleSavings - adjustments.additionalSavings, 0);
  const totalSavings = calculatedTotal.original - totalValue;

  return {
    original: calculatedTotal.original,
    totalValue: parsePriceValue(totalValue || calculatedTotal.original),
    totalSavings,
    hasTotalSavings: totalValue > 0 && totalSavings > 0,
    percentage: (totalSavings && calculatedTotal.value ? ((totalSavings / calculatedTotal.original) * 100) : 0),
    showMapMessage: calculatedTotal.showMapMessage
  };
};

export const getBundleWithPricing = ({ bundle = {}, pricing = {} }) => (
  {
    ...bundle,
    pricing,
    info: {
      ...bundle?.info,
      sskMax: null,
      sskMin: null
    },
    disableRangePricing: true,
    omitDetails: false,
    showStartingAt: false
  }
);