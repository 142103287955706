import { useState, useEffect } from 'react';
import {
  getProductsFulfillmentInformation,
  getAreAnyProductsOOS,
  getFulfillmentAvaiable,
  shouldDisableATCButton
} from '../utils/product-bundle-utils';

const useProductsFullfilmentValidation = ({
  products,
  bundleQuantity,
  quantityLimitInventory,
  deliveryZip,
  storeZip,
  addToCartFlag,
  disableATCForMissingProductsFlag
}) => {
  const [disableATC, setDisableATC] = useState(true);
  const [productsFulfillmentInformation, setProductsFulfillmentInformation] = useState(null);
  const [currentlyOOS, setCurrentlyOOS] = useState(null);
  const [fulfillmentUnavailable, setFulfillmentUnavailable] = useState(false);

  useEffect(() => {
    if (products) {
      const productsFulfillmentData = getProductsFulfillmentInformation({
        products,
        deliveryZip,
        storeZip
      });

      setProductsFulfillmentInformation(productsFulfillmentData);

      const areAnyProductsOOS = getAreAnyProductsOOS({ products });

      setCurrentlyOOS(areAnyProductsOOS || productsFulfillmentData?.cartingOptions?.backordered);

      const isFulfillmentAvailable = getFulfillmentAvaiable({ products });

      setFulfillmentUnavailable(!isFulfillmentAvailable);
    }
  }, [products]);

  useEffect(() => {
    const isAtcDisabled = shouldDisableATCButton({
      products,
      quantityBelowLimit: quantityLimitInventory >= bundleQuantity,
      productsFulfillmentInformation,
      disableATCForMissingProductsFlag,
      addToCartFlag
    });

    setDisableATC(isAtcDisabled);

  }, [bundleQuantity, products, productsFulfillmentInformation, quantityLimitInventory]);

  return {
    disableATC,
    productsFulfillmentInformation,
    currentlyOOS,
    fulfillmentUnavailable
  };
};

export default useProductsFullfilmentValidation;
