/* eslint-disable max-len */
export default {
  header: 'Gas or Electric',
  info: [
    {
      title: 'Tips for Choosing a Gas or Electric Dryer',
      sections: [
        {
          text: 'When making the choice between gas vs. electric dryers for your home, you will want to consider things like energy usage, existing hookups in your laundry room and your overall household budget.'
        }
      ]
    },
    {
      title: 'Gas Dryer',
      sections: [
        {
          text: 'Gas dryers plug into a dedicated 120-volt outlet and need a gas line in the laundry space. Professional installation is recommended.'
        },
        {
          header: 'Benefits:',
          list: [
            'Gas can be a cost-effective and affordable fuel over the long run',
            'Typically more energy-efficient, gas uses nearly half the amount of electricity compared to electric dryers',
            'Dry loads in about half the time of electric dryers and produce less static cling',
            'They can keep clothes from wrinkling because gas dryers dissipate heat faster after the end of the cycle'
          ]
        }
      ]
    },
    {
      title: 'Electric Dryer',
      sections: [
        {
          text: 'Electric dryers plug into a dedicated 220-volt outlet and can be installed by a homeowner.'
        },
        {
          header: 'Benefits:',
          list: [
            'Lower upfront cost',
            'Most homes already have appropriate wiring in place',
            'Easier to install than gas dryers',
            'Relatively low-maintenance and can have budget-friendly repair costs',
            'Some electric dryers have a steam dry function to reduce wrinkles and static'
          ]
        }
      ]
    },
    {
      title: 'Additional Information',
      sections: [
        {
          header: 'Consider the following when shopping for new appliances:',
          list: [
            '3-prong plugs are used for homes built before the year 2000',
            '4-prong plugs are used for homes built after the year 2000',
            'Check the dryer\'s prong amount before purchase as well. Some dryers are still made for 3-prong outlets to accommodate older homes'
          ]
        },
        {
          text: 'When you purchase a new appliance from us without professional installation, a 4-prong cord is sent by default. If your home is equipped with a 3-prong outlet, you\'ll need to purchase a 3-prong cord or connector adapter separately.'
        }
      ]
    }
  ]
};
