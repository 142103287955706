import React, { useEffect, useContext } from 'react';
import { object, shape as shapeProp, string as stringType, arrayOf as arrayProp } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Typography, Card, CardMedia } from '@one-thd/sui-atomic-components';
import { ProductOverviewAccordionBody } from '@thd-olt-component-react/product-overview';
import {
  params, string, arrayOf,
  shape, extend, QueryProvider, QueryContext
} from '@thd-nucleus/data-sources';
import useSetSelectedItemId from '../hooks/useSetSelectedItemId';
import { CARD_MEDIA_SM, CARD_MEDIA_MD, CARD_MEDIA_LG } from '../constants';
import { ProductDetailsButton } from './subcomponents/ProductDetailsButton';
import {
  tiggerAnalyticsCustomEvent, getDdo, getIsMobile, getProductImage, filterRemovedProducts
} from '../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {array} products - list of products that are in the bundle
 */

/**
 * Properties of a product
 *
 * @typedef {object} Product
 * @property {string} itemId The identifier of the element
 * @property {string} media Contains the image to show on the product list
 * @property {string} identifiers Contains the title to show on the product list
 */

/**
 * Returns a formated Product List of the provided data showing title and a related-product detail
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const BundleProductDetails = ({ product: bundleProduct, products, itemId }) => {
  const experienceContext = useContext(ExperienceContext);
  const { channel } = experienceContext;
  const isMobile = getIsMobile(channel);
  const { defaultVariables } = useContext(QueryContext) || {};

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundle-product-details.ready');
  }, []);

  const { selectedItemId, changeSelectedItem } = useSetSelectedItemId({ products });

  const visibleProducts = filterRemovedProducts(products);

  const selectedProduct = visibleProducts?.find((item) => item.itemId === selectedItemId);

  /**
  * Handles the click on an item and updates the state of the selected item
   *
   * @param {Object} product - information of the selected item
   * @returns {void}
   */
  const handleItemClick = (product) => {
    changeSelectedItem(product?.itemId);
    tiggerAnalyticsCustomEvent('zone-b.click', getDdo(product, 'product-details-bip'));
  };

  if (!visibleProducts?.length) {
    return null;
  }

  return (
    <div className="sui-max-w-full" data-component="BundleProductDetails" data-testid="bundle-product-details">
      <div className={`${isMobile ? 'sui-grid-flow-col' : 'sui-grid-cols-5'} sui-grid sui-grid-rows-1 sui-gap-4`}>
        {visibleProducts?.map((product, index) => (
          product?.itemId && (
            <Card
              key={product.itemId}
              className={`${
                selectedItemId === product.itemId ? 'sui-border-2 sui-border-strongest' : 'sui-border-1'
              } sui-max-w-xs`}
              onClick={() => handleItemClick(product)}
            >
              <CardMedia
                data-testid={`bundle-product-detail-button-${index}`}
                component="img"
                height={{ sm: CARD_MEDIA_SM, md: CARD_MEDIA_MD, lg: CARD_MEDIA_LG }}
                width={{ sm: CARD_MEDIA_SM, md: CARD_MEDIA_MD, lg: CARD_MEDIA_LG }}
                src={getProductImage(product?.media?.images)}
                alt={product?.title}
              />
            </Card>
          )
        ))}
      </div>
      {selectedItemId ? (
        <div data-component="BodyBundleProductDetails">
          {selectedProduct && selectedProduct !== null ? (
            <div className="sui-grid sui-mt-4">
              <Typography variant="body-lg" weight="bold" data-testid="tile-product-title">
                {selectedProduct?.identifiers?.productLabel}
              </Typography>
            </div>
          ) : null}
          <div className="sui-mt-4" data-testid="product-detail-accordion-body">
            <QueryProvider cacheKey="product-overview-accordion-body" defaultVariables={defaultVariables?.current}>
              <ProductOverviewAccordionBody itemId={selectedItemId} />
            </QueryProvider>
          </div>
          <div className="sui-mt-6" id="productDetailsPipLink">
            <ProductDetailsButton
              canonicalUrl={selectedProduct?.identifiers?.canonicalUrl}
              product={bundleProduct}
              itemId={itemId}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

BundleProductDetails.propTypes = {
  products: arrayProp(shapeProp({ object })),
  product: shapeProp({ object }),
  itemId: stringType.isRequired
};

BundleProductDetails.defaultProps = {
  products: null,
  product: null
};

BundleProductDetails.displayName = 'BundleProductDetails';

BundleProductDetails.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      bundleSpecificationDetails: shape({
        type: string(),
        experienceType: string()
      })
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      itemId: string(),
      media: {
        images: arrayOf({
          sizes: arrayOf(string()),
          subType: string(),
          type: string(),
          url: string()
        })
      },
      identifiers: shape({
        canonicalUrl: string(),
        productLabel: string()
      })
    })
  },
  ProductOverviewAccordionBody
);

export { BundleProductDetails };
