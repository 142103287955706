/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { MOBILE } from '@thd-olt-functional/utils';
import { FULFILLMENT_METHODS } from '@thd-olt-component-react/fulfillment/dist/components/constants';
import {
  getAllFulfillmentInventories,
  getBOSSFulfillment,
  isSthInStock,
  getBOPISFulfillment,
  getBODFSFulfillment,
  getInventory
} from '@thd-olt-component-react/fulfillment/dist/pod/helpers/pod-fulfillment-utils';
import { getStateFromZipcode } from '@thd-olt-component-react/fulfillment/dist/pod/helpers/zipcode-helper';
import { getStateName, getStateCode } from '@thd-olt-component-react/fulfillment/dist/components/helper/StateCodes';
import { isLocationInExcludedStates } from '@thd-olt-component-react/fulfillment/dist/components/helper/utils';
import { mockLaundrySelectableVariantdata } from './productBundleData';
import {
  SKU_NOT_VALID, DYNAMIC_IMAGE_SIZE, CART_BUNDLE_TYPE, SHOW_SPECIAL_PRICE_IF_AUTH,
  SHOW_PRICE_IN_CART, ADD_TO_CART_ANALYTICS, BIP_TO_PIP_PRODUCT_VIEW, EXPERIENCE_TYPE_LAUNDRY,
  KITCHEN_INCOMPLETE_TOASTER_KEY, LAUNDRY_INCOMPLETE_TOASTER_KEY, GM_COMBO_INCOMPLETE_TOASTER_KEY,
  BUNNDLE_TYPE_PACKAGE, PRODUCT_BUNDLE_ACCORDION_OPEN, PRODUCT_BUNDLE_PRODUCT_LIST_ITEM_CLICK
} from '../constants/index';
import { getPrice } from '../api/priceHandler';

const flattenArray = (array, name) => {
  return (array?.map((item) => (item.media[name] || [])) || []).flat();
};

export const getProduct = (productData, configExperienceType, configBundleType, configBundleProducts) => {
  if (configExperienceType === EXPERIENCE_TYPE_LAUNDRY && configBundleType === BUNNDLE_TYPE_PACKAGE && productData) {
    let component = productData?.bundleSpecificationDetails?.components?.map((ele, index) => {
      return {
        ...ele,
        defaultProductId: configBundleProducts?.[index] ?? ele?.defaultProductId,
        selectableVariants: mockLaundrySelectableVariantdata,
        allowRemove: null,
        allowSwap: null
      };
    });
    return {
      ...productData,
      bundleSpecificationDetails: {
        ...productData?.bundleSpecificationDetails,
        components: component?.slice(0, configBundleProducts?.length)
      }
    };
  }

  return productData || {};
};

export const getProductIndex = (productId, products) => products.findIndex((product) => product.itemId === productId);

export const getIsMobile = (channel) => channel === MOBILE;

export const hasMissingProducts = (products) => {
  return products?.some((product) => (product?.isIncomplete));
};

export const getParentMedia = (parent, products) => {
  if (hasMissingProducts(products)) {
    return null;
  }
  return parent?.media;
};

export const getActiveProducts = (products = []) => {
  return products?.filter((product) => !product?.isDiscontinued);
};

export const getBundleMedia = (parent, bundles) => {
  if (!parent?.media) {
    return null;
  }
  const activeProducts = getActiveProducts(bundles);
  const parentMedia = getParentMedia(parent, bundles);

  const images = flattenArray(activeProducts, 'images');
  const video = flattenArray(activeProducts, 'video');
  const threeSixty = flattenArray(activeProducts, 'threeSixty');

  const primaryImage = parentMedia?.images?.find((i) => i.subType === 'PRIMARY');
  if (primaryImage) {
    images.unshift(primaryImage);
  }

  return {
    media: {
      image: parentMedia?.image,
      augmentedRealityLink: parentMedia?.augmentedRealityLink,
      images,
      video,
      threeSixty
    }
  };
};

export const ratingToggleFromProductOverview = ({ accordionId }) => {
  const accordionSelected = document.querySelector(accordionId);
  if (accordionSelected) {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(PRODUCT_BUNDLE_ACCORDION_OPEN, accordionId);
  }
};

export const getDynamicImages = (products) => {
  const images = flattenArray(products, 'images');
  const dynamicImages = images?.filter((image) => image.subType === 'PRIMARY')?.map((image, i) => {
    return {
      url: image.url.replace('<SIZE>', DYNAMIC_IMAGE_SIZE),
      itemId: products[i].itemId,
      active: !products[i].removed,
      productGroupLabel: products[i].itemId
    };
  });

  return dynamicImages || [];
};

export const accordionToggle = ({ accordionName }) => {
  const accordionSpecifications = document.querySelector(accordionName);
  let accordionButton;
  let isExpanded;
  if (typeof (accordionSpecifications) !== 'undefined' && accordionSpecifications != null) {
    accordionButton = accordionSpecifications.querySelector('[role="button"]');

    isExpanded = accordionButton.getAttribute('aria-expanded') === 'true';

    if (!isExpanded && accordionButton) {
      accordionButton.click();
    }
  }
};

export const scrollTo = ({ offset, accordionName }) => {
  if (!offset) {
    return;
  }

  const onScroll = function () {
    const scrollY = Number(window.scrollY.toFixed());
    const checkOffset = Number(offset);
    const documentHeight = Number(document.documentElement.scrollHeight.toFixed());
    const windowHeight = scrollY + Number(window.innerHeight.toFixed());

    if (scrollY >= checkOffset || documentHeight === windowHeight) {
      window.removeEventListener('scroll', onScroll);
      accordionToggle({ accordionName });
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();

  window.scrollTo({
    top: offset,
    behavior: 'smooth'
  });
};

export const onClickProductAccordionLink = ({
  event,
  product,
  isMobile,
  scrollStickyHeaderHeight,
  accordionName
}) => {
  if (!product) {
    return;
  }
  const selectedAccordion = document.querySelector(accordionName);

  if (typeof (selectedAccordion) !== 'undefined' && selectedAccordion != null) {
    const offset = isMobile
      ? selectedAccordion.offsetTop
      : selectedAccordion.offsetTop - scrollStickyHeaderHeight;

    scrollTo({ offset, accordionName, isMobile });

    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      PRODUCT_BUNDLE_PRODUCT_LIST_ITEM_CLICK,
      product
    );
    if (selectedAccordion) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(PRODUCT_BUNDLE_ACCORDION_OPEN, accordionName);
    }
    window.history.pushState(null, null, accordionName);
    event.preventDefault();
  }
};

export const tiggerAnalyticsCustomEvent = (eventName, data) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(eventName, data);
  }
};

export const isFromBundleMini = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return document?.referrer?.indexOf('/p/') >= 0 && document?.referrer?.indexOf('/p/sets/') === -1;
};

export const triggerViewFullProductAnalytics = (itemId, product) => {
  if (isFromBundleMini()) {
    const output = {
      itemId,
      product
    };
    tiggerAnalyticsCustomEvent(BIP_TO_PIP_PRODUCT_VIEW, output);
  }
};

const triggerAddToCartAnalytics = (cartItems, product) => {
  if (isFromBundleMini()) {
    const output = {
      items: cartItems,
      product
    };
    tiggerAnalyticsCustomEvent(ADD_TO_CART_ANALYTICS, output);
  }
};

export const bundleAddToCart = ({ cartItems, storeId, zipCode, product }) => {
  const cartOptions = {
    alterBrowserHistory: true,
    misship: true,
    channel: 'desktop',
    configurableItems: cartItems,
    storeId,
    storeZip: zipCode,
    zipCode,
    bypassBss: true,
    bss: true,
    directCheckout: true,
    hasConfigurableItems: false,
    component: 'add both'
  };
  window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
    cartReqParams: cartItems,
    cartOptions,
    path: ['buybox', 'addtocart']
  });
  triggerAddToCartAnalytics(cartItems, product);
};

/**
 * @typedef {Object} BundleComponents
 * @property {number} quantity - Component Quantity
 */

/**
 * @typedef {Object} BundleSpecificationDetails
 * @property {string} type - Product type (combo, configurable, etc)
 * @property {BundleComponents[]} components - Components
/**
 * Check if bundle product is Major Appliances
 * @param {{bundleSpecificationDetails: BundleSpecificationDetails}} product - Bundle details
 */

export const getCustomerType = () => {
  if (typeof window?.THDCustomer !== 'undefined') {
    return window?.THDCustomer?.default?.customerType || 'B2C';
  }

  return 'B2C';
};

export const getUserLoggedIn = () => {
  if (typeof window?.THDCustomer !== 'undefined') {
    return window?.THDCustomer?.default?.isLoggedIn;
  }

  return false;
};

export const getB2BUser = () => {
  if (typeof window !== 'undefined' && typeof window?.THDCustomer !== 'undefined') {
    return window?.THDCustomer?.default?.isB2BCustomer;
  }

  return false;
};

export const getB2CUser = () => {
  if (typeof window !== 'undefined' && typeof window?.THDCustomer !== 'undefined') {
    return window?.THDCustomer?.default?.isB2CCustomer;
  }
  return false;
};

export const getSVOCID = () => {
  if (typeof window?.THDCustomer !== 'undefined') {
    return window?.THDCustomer?.default?.svocID || '0000000';
  }

  return '0000000';
};

export const getMembershipInformation = () => {
  if (typeof window?.THDCustomer !== 'undefined') {
    return window?.THDCustomer?.default?.membershipInformation?.programTiers || null;
  }

  return null;
};

export const getDdo = (product, component) => {
  return {
    section: 'zone-b',
    component,
    target: product?.identifiers?.productLabel
  };
};

/**
 * Accepts a number and returns a formatted price with USD $
 * and 2 decimal places.
 * @param {number} price
 * @returns {string} formatted price
 */
export const formatPrice = (price) => {
  if (!price) {
    return null;
  }

  const formattedPrice = parseFloat(price).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return formattedPrice;
};

export const roundNumber = ({ number, decimal = 0 }) => {
  if (!number) {
    return null;
  }

  const newRoundNumber = parseFloat(number)
    .toLocaleString('en-US', { minimumFractionDigits: decimal, maximumFractionDigits: decimal })
    .replaceAll(',', '');

  return Number(newRoundNumber);
};

export const getValidProducts = ({ products }) => {
  const updatedProducts = products?.map((product, index) => {
    return {
      itemId: product?.identifiers?.storeSkuNumber || product?.identifiers?.omsThdSku,
      refId: index,
      qty: product?.quantity,
      price: product?.pricing?.value || product?.pricing?.original,
      hasDisc: false,
      globalExclsn: false
    };
  });

  const validProducts = updatedProducts?.reduce((accumulatedValidProducts, product) => {
    if (product?.itemId && product?.price) {
      accumulatedValidProducts.push(product);
    }

    return accumulatedValidProducts;
  }, []);

  return validProducts;
};

/**
 * Accepts an array of product images and returns an image to use in the list.
 * @param {array} ProductImages
 * @returns {string} image
 */
export const getProductImage = (ProductImages) => {
  if (!ProductImages) {
    return '';
  }
  // find primary
  const primaryImage = ProductImages?.find((image) => image?.subType === 'PRIMARY');
  const primaryImageUrl = primaryImage?.url;
  const setImages = primaryImage?.sizes.map((size) => primaryImageUrl.replace(/<SIZE>/i, size));
  const bundleMainImage = setImages[3];
  return bundleMainImage;
};

export const getCustomerSegments = () => {
  const membershipInformation = getMembershipInformation();
  const customerSegments = membershipInformation?.map((information) => {
    return {
      programId: information?.program,
      tierId: information?.tier
    };
  });

  return customerSegments;
};

export const getCartRequestInfo = ({ products }) => {
  const custType = getCustomerType();
  const svocId = getSVOCID();
  const isAuthenticated = getUserLoggedIn();
  const validProducts = getValidProducts({ products });

  const cartRequest = {
    cartRequest: {
      custId: svocId,
      custType,
      orderId: '000000',
      location: '8119',
      channel: 'INET',
      items: validProducts,
      svocId,
      isAuthenticated,
      customerSegments: getCustomerSegments()
    }
  };

  return cartRequest;
};

export const adjustProductsPricing = (products, productsAdjustedPricing) => {
  let bundleSavings = 0;
  let bundleItemSavings = 0;
  let bundleSummaryPrice = 0;
  let bundleSavingsByPromos = {};

  const priceAdjustedProducts = products?.reduce((acc, product) => {
    const foundInCartAdjustedPricing = productsAdjustedPricing?.find(
      (productAdjustedPricing) =>
        productAdjustedPricing?.itemId === product?.identifiers?.storeSkuNumber
    );
    const priceValue = product?.pricing?.value || 0;
    const original = product?.pricing?.original || 0;
    const itemDollarOff = product?.pricing?.promotion?.dollarOff || 0;
    const totalItemPrice = original || priceValue;
    bundleSummaryPrice += totalItemPrice || 0;
    const totalLineItemDiscAmt = Math.abs(Number(foundInCartAdjustedPricing?.discounts?.totalLineItemDiscAmt)) || 0;
    const newDollarOff = totalLineItemDiscAmt + itemDollarOff;
    const newPercentOff = ((totalLineItemDiscAmt + itemDollarOff) / totalItemPrice) * 100;
    const newProductValue = foundInCartAdjustedPricing?.price - newDollarOff || foundInCartAdjustedPricing?.price;
    const newProductOriginalValue = foundInCartAdjustedPricing?.price;
    foundInCartAdjustedPricing?.discounts?.discDetails?.forEach((discDetail) => {
      const discountAmount = Math.abs(Number(discDetail.discAmt)) || 0;
      const promoId = discDetail?.promoId || 0;
      if (bundleSavingsByPromos[promoId]) {
        bundleSavingsByPromos[promoId].savings += discountAmount;
      } else {
        bundleSavingsByPromos[promoId] = {
          promoId,
          savings: discountAmount,
          shortDesc: discDetail.shortDesc,
          longDesc: discDetail.longDesc
        };
      }
      bundleSavings += discountAmount;
    });
    bundleItemSavings += itemDollarOff;
    const newProduct = {
      ...product,
      pricing: {
        ...product?.pricing,
        value: newProductValue,
        alternatePriceDisplay: false,
        original: product?.pricing?.original || newProductOriginalValue,
        promotion: {
          ...product?.pricing?.promotion,
          dollarOff: newDollarOff,
          percentageOff: newPercentOff
        }
      }
    };

    acc.push(newProduct);
    return acc;
  }, []);

  return {
    bundleSavings,
    bundleSavingsByPromos,
    bundleItemSavings,
    bundleSummaryPrice,
    priceAdjustedProducts
  };
};

export const getFulfillmentLocationByMethod = ({ method, storeZip, storeId }) => {
  if (method === FULFILLMENT_METHODS.BOPIS || method === FULFILLMENT_METHODS.BOSS) {
    return storeId;
  }

  return storeZip;
};

export const getCartItems = ({
  cartingOptions, storeZip, storeId, qty, bundleId, experienceType
}) => {
  let items = null;
  if (cartingOptions?.products?.map) {
    items = cartingOptions?.products.map((product) => {
      return {
        itemId: product?.itemId,
        fulfillmentLocation: getFulfillmentLocationByMethod({ method: product?.cartMethod?.[0], storeZip, storeId }),
        fulfillmentMethod: product?.cartMethod?.[0],
        quantity: qty && product?.quantity ? (qty * product.quantity) : 1,
        storeId,
        storeSkuNumber: product?.storeSkuNumber,
        unitPrice: product?.unitPrice,
        zipCode: storeZip,
        configuration: {
          id: bundleId,
          type: CART_BUNDLE_TYPE,
          categoryType: experienceType
        }
      };
    });
  }

  return items;
};

/**
 * @typedef {Object} FulfillmentObject Fulfillment object
 * @property {boolean} isPickUp Pickup value
 * @property {boolean} isDelivery Delivery value
 * @property {boolean} isScheduled Scheduled delivery value
 */
/**
 * Accepts a product and returns an object with boolean values of fulfillment info
 * @param {object} Product Product object
 * @returns {FulfillmentObject} Fulfillment data
 */
const getFulfillmentDetails = (product) => {
  let isPickUp = false;
  let isDelivery = false;
  let isScheduled = false;
  (product.fulfillment?.fulfillmentOptions || []).forEach((option) => {
    if (option?.type === 'pickup') {
      isPickUp = true;
    } else if (option?.type === 'delivery') {
      isDelivery = true;
      isScheduled = (option?.services || []).some((service) => service.type === 'express delivery');
    }
  });

  return {
    isPickUp,
    isDelivery,
    isScheduled
  };
};

/**
 * Accepts an array of products and returns an object with boolean values of fulfillment info
 * showing whether or not some products are fulfillable by pickup, delivery or scheduled delivery
 * @param {Product[]} Products Products array
 * @returns {FulfillmentObject} Fulfillment data
 */
export const getFulfillmentInformationSummary = (products) => {
  const fulfillmentInformationSummary = products?.map((product) => getFulfillmentDetails(product)) || [];

  return {
    isPickUp: fulfillmentInformationSummary.some((product) => product?.isPickUp),
    isDelivery: fulfillmentInformationSummary.some((product) => product?.isDelivery),
    isScheduled: fulfillmentInformationSummary.some((product) => product?.isScheduled)
  };
};

export const noop = () => { return undefined; };

export const getBundlePriceCalculations = (
  {
    bundleSavings,
    bundleItemSavings,
    bundleSummaryPrice
  }, deliveryFee) => {

  /** @type {number} */
  const subTotal = bundleSummaryPrice - bundleItemSavings;

  /** @type {number} */
  const finalDiscountedPrice = subTotal - bundleSavings;

  /** @type {number} */
  const finalDiscountedPriceWithDeliveryFee = finalDiscountedPrice + deliveryFee;

  /** @type {number} */
  const totalDollarOff = bundleSavings + bundleItemSavings;

  /** @type {number} */
  const totalPercentOff = (totalDollarOff / bundleSummaryPrice) * 100;

  return {
    subTotal,
    finalDiscountedPrice,
    finalDiscountedPriceWithDeliveryFee,
    totalDollarOff,
    totalPercentOff
  };
};

export const fullFillmentMethodOrder = [
  FULFILLMENT_METHODS.STH,
  FULFILLMENT_METHODS.BOPIS,
  FULFILLMENT_METHODS.BOSS,
  FULFILLMENT_METHODS.BODFS
];

export const getMethodLowestInventory = ({ products, method }) => {
  const allSameMethodCount = products?.length;

  const lowest = products?.reduce(
    (acc, product) => {
      const itemId = product?.itemId;
      let accInventory = acc?.inventory;
      let methodCount = acc?.sameMethod?.count;
      const currentProductQuantity = product?.inventoryTypes?.[method];
      const productQuantityLimit = product?.quantityLimit || 0;
      let newQuantityLimit = currentProductQuantity;

      if (currentProductQuantity !== 0) {
        methodCount += 1;
        if (productQuantityLimit !== 0 && productQuantityLimit < currentProductQuantity) {
          newQuantityLimit = productQuantityLimit;
        }
        if (newQuantityLimit < accInventory) {
          accInventory = newQuantityLimit;
        }
      }

      return {
        inventory: accInventory,
        sameMethod: { count: methodCount, value: allSameMethodCount === methodCount },
        itemId,
        filfillmentMethod: method
      };
    },
    {
      inventory: Infinity,
      sameMethod: { count: 0, value: false },
      itemId: null,
      filfillmentMethod: method
    }
  );

  return lowest;
};

export const getProductsFulfillmentInventories = ({ products }) => {
  const inventories = products?.map((product) => {
    const allFulfillmentInventories = getAllFulfillmentInventories(product);
    const bodfsFulfillment = getBODFSFulfillment(product);
    const bodfsInventroy = getInventory(bodfsFulfillment);
    const inventory = { ...allFulfillmentInventories, [FULFILLMENT_METHODS.BODFS]: bodfsInventroy?.quantity };

    return {
      inventory,
      itemId: product?.itemId,
      quantityLimit: product?.info?.quantityLimit || 0,
      excludedShipStates: product?.fulfillment?.excludedShipStates,
      bossExcludedShipStates: product?.fulfillment?.bossExcludedShipStates,
      fulfillmentOptionsAvailable: !!product?.fulfillment?.fulfillmentOptions
    };
  });

  return inventories;
};

export const getAreAnyProductsOOS = ({ products }) => {
  const productInventories = getProductsFulfillmentInventories({ products });
  const oosStatus = productInventories?.reduce((oosAccumulator, product) => {
    if (oosAccumulator) {
      return oosAccumulator;
    }

    const inventoryFound = Object.keys(product?.inventory).find((inventoryKey) => product?.inventory[inventoryKey] !== 0);

    if (!inventoryFound && product?.fulfillmentOptionsAvailable) {
      return true;
    }

    return oosAccumulator;
  }, false);

  return oosStatus;
};

export const getIsFulfillmentAvailable = ({ productInventories }) => {
  const fulfillmentStatus = productInventories?.reduce((accumulatorFulfillmentAvailable, product) => {
    if (!accumulatorFulfillmentAvailable) {
      return accumulatorFulfillmentAvailable;
    }

    return !!product?.fulfillmentOptionsAvailable;
  }, true);

  return fulfillmentStatus;
};

export const getFulfillmentAvaiable = ({ products }) => {
  const productInventories = getProductsFulfillmentInventories({ products });
  const isFulfillmentAvailable = getIsFulfillmentAvailable({ productInventories });

  return isFulfillmentAvailable;
};

export const getStateNameStateCode = ({ deliveryZip, storeZip = undefined }) => {
  const deliveryStateFromZipcode = getStateFromZipcode(deliveryZip);
  const deliveryStateName = getStateName(deliveryStateFromZipcode);
  const deliveryStateCode = getStateCode(deliveryStateName);
  const storeStateFromZipcode = getStateFromZipcode(storeZip);
  const storeStateName = getStateName(storeStateFromZipcode);
  const storeStateCode = getStateCode(storeStateName);

  return { deliveryStateName, deliveryStateCode, storeStateName, storeStateCode };
};

export const getExcludedStatus = ({ product, storeStateCode, deliveryStateCode }) => {
  let status = false;

  if (storeStateCode) {
    const productBossExcludedShipStates = product?.bossExcludedShipStates || product?.fulfillment?.bossExcludedShipStates;
    const bossExcluded = isLocationInExcludedStates(storeStateCode, productBossExcludedShipStates);
    status = bossExcluded;
  }

  if (deliveryStateCode) {
    const productExcludedShipStates = product?.excludedShipStates || product?.fulfillment?.excludedShipStates;
    const shipExcluded = isLocationInExcludedStates(deliveryStateCode, productExcludedShipStates);
    status = shipExcluded;
  }

  return { status };
};

export const validateFulfillmentOptionIsViable = ({
  type, fulfillmentOptionStatus, deliveryZip, storeZip, product
}) => {
  if (!fulfillmentOptionStatus) {
    return false;
  }

  const { deliveryStateCode = '' } = getStateNameStateCode({ deliveryZip });
  const { storeStateCode = '' } = getStateNameStateCode({ storeZip });
  const bossExcluded = getExcludedStatus({ product, storeStateCode });
  const shipExcluded = getExcludedStatus({ product, deliveryStateCode });

  if (type === FULFILLMENT_METHODS.STH) {
    return fulfillmentOptionStatus && !shipExcluded?.status;
  }

  if (type === FULFILLMENT_METHODS.BOSS) {
    return fulfillmentOptionStatus && !bossExcluded?.status;
  }

  if (type === FULFILLMENT_METHODS.BODFS) {
    return fulfillmentOptionStatus && !shipExcluded?.status;
  }

  return null;
};

export const verifyBopisFulfillment = ({ product }) => {
  let BOPISFulfillment = false;
  const BOPISFulfillmentFromProduct = getBOPISFulfillment(product);

  if (BOPISFulfillmentFromProduct?.locations?.length >= 1) {
    const BOPISFulfillmentLocation = BOPISFulfillmentFromProduct.locations?.[0];
    BOPISFulfillment = BOPISFulfillmentLocation?.inventory?.quantity > 0;
  }

  return BOPISFulfillment ? BOPISFulfillmentFromProduct : false;
};

const getFulfillmentMethodAcc = (method, type, fulfillable) => {
  return !!(method === type && fulfillable);
};

const getCartMethod = (product, deliveryZip, storeZip) => {
  return fullFillmentMethodOrder?.reduce((fulfillMentMethodAcc, method) => {
    const sthInStock = validateFulfillmentOptionIsViable({
      type: FULFILLMENT_METHODS.STH,
      fulfillmentOptionStatus: isSthInStock(product),
      deliveryZip,
      product
    });
    const BOPISFulfillment = verifyBopisFulfillment({ product });

    const BOSSFulfillment = validateFulfillmentOptionIsViable({
      type: FULFILLMENT_METHODS.BOSS,
      fulfillmentOptionStatus: getBOSSFulfillment(product),
      storeZip,
      product
    });
    const BODFSFulfillment = validateFulfillmentOptionIsViable({
      type: FULFILLMENT_METHODS.BODFS,
      fulfillmentOptionStatus: getBODFSFulfillment(product),
      storeZip,
      product
    });

    if (!sthInStock && !BOPISFulfillment && !BOSSFulfillment && !BODFSFulfillment) {
      return fulfillMentMethodAcc;
    }

    if (getFulfillmentMethodAcc(method, FULFILLMENT_METHODS.STH, sthInStock)) {
      fulfillMentMethodAcc.push(method);
    }

    if (getFulfillmentMethodAcc(method, FULFILLMENT_METHODS.BOPIS, BOPISFulfillment)) {
      fulfillMentMethodAcc.push(method);
    }

    if (getFulfillmentMethodAcc(method, FULFILLMENT_METHODS.BOSS, BOSSFulfillment)) {
      fulfillMentMethodAcc.push(method);
    }

    if (getFulfillmentMethodAcc(method, FULFILLMENT_METHODS.BODFS, BODFSFulfillment)) {
      fulfillMentMethodAcc.push(FULFILLMENT_METHODS.BODFS);
    }

    return fulfillMentMethodAcc;
  }, []);
};
const getcartingOptions = (products, deliveryZip, storeZip) => {
  return products?.reduce(
    (acc, product) => {
      if (product?.fulfillment?.backordered) {
        acc.backordered = true;
      }

      // priority fulfillment: STH -> BOPIS/BOSS -> BODFS
      const cartMethod = getCartMethod(product, deliveryZip, storeZip);

      acc.products.push({
        itemId: product?.itemId,
        cartMethod: product?.features?.directDeliveryCartMethod ? ['DirectDelivery'] : cartMethod,
        storeSkuNumber: product?.identifiers?.storeSkuNumber,
        unitPrice: product?.pricing?.value,
        quantity: product?.quantity || 1
      });
      return acc;
    },
    { backordered: false, products: [] }
  );
};

export const areProductsValid = (products) => products && Array.isArray(products) && products.length > 0;

/**
 * Get Bundle Quantity
 * @param {{bundleSpecificationDetails: BundleComponents[]}} products - Bundle details
 */
export const getMaxQuantityComponentInBundle = (products = []) => {
  if (!areProductsValid(products)) return 1;

  return products.reduce((maxQuantity, component) => {
    const quantity = Number(component.quantity);

    if (Number.isNaN(quantity)) return maxQuantity;
    if (quantity > maxQuantity) return quantity;
    return maxQuantity;
  }, 1);
};

export const getMaxQuantityLimit = (products) => {
  if (!areProductsValid(products)) {
    return {
      quantityLimit: 0,
      quantityLimitWithInventory: 1
    };
  }

  const productsFulfillmentInventories = getProductsFulfillmentInventories({ products });

  let someProductHasQuantityLimit = false;

  const parsedAllProductsInventoryParsed = productsFulfillmentInventories?.map((product) => {
    const quantityLimit = product?.quantityLimit || 0;

    if (!someProductHasQuantityLimit && quantityLimit > 0) {
      someProductHasQuantityLimit = true;
    }

    return {
      inventoryTypes: product?.inventory,
      itemId: product?.itemId,
      quantityLimit
    };
  });

  const productsFulfillmentMethodInventory = fullFillmentMethodOrder.reduce((methodAcc, method) => {
    const methodLowestInventory = getMethodLowestInventory({
      products: parsedAllProductsInventoryParsed,
      method
    });

    if (methodLowestInventory?.inventory === Infinity) {
      return methodAcc;
    }

    methodAcc.push(methodLowestInventory);

    return methodAcc;
  }, []);

  if (productsFulfillmentMethodInventory.length === 0) {
    return {
      quantityLimit: 0,
      quantityLimitWithInventory: 1
    };
  }

  const lowestOverallInventory = productsFulfillmentMethodInventory?.reduce((productsFulfillmentAcc, product) => {
    if (product?.sameMethod?.value && !productsFulfillmentAcc?.sthSameMethod) {
      return {
        ...productsFulfillmentAcc,
        lowestInventory: product?.inventory,
        sthSameMethod: true
      };
    }

    if (product?.inventory < productsFulfillmentAcc?.lowestInventory && !productsFulfillmentAcc?.sthSameMethod) {
      return {
        ...productsFulfillmentAcc,
        lowestInventory: product?.inventory
      };
    }

    return productsFulfillmentAcc;
  }, { lowestInventory: Infinity, sthSameMethod: false });

  const maxQuantityInComponent = getMaxQuantityComponentInBundle(products);
  const maxQuantityLimit = Math.floor(lowestOverallInventory?.lowestInventory / maxQuantityInComponent);

  return {
    quantityLimit: someProductHasQuantityLimit ? maxQuantityLimit : 0,
    quantityLimitWithInventory: maxQuantityLimit
  };
};

export const getProductsFulfillmentInformation = ({ products, deliveryZip, storeZip }) => {
  if (!products) {
    return {
      maxQuantityLimit: {
        quantityLimit: 0,
        quantityLimitWithInventory: 1
      },
      cartingOptions: {
        backordered: true,
        products: []
      }
    };
  }

  const cartingOptions = getcartingOptions(products, deliveryZip, storeZip);

  return { cartingOptions };
};

export const getProductsMissingFulfillmentOptions = ({ products }) => {
  if (!products) {
    return false;
  }

  const hasFullfillmentOptions = products?.reduce((boolAcc, product) => {
    if (product?.fulfillment?.fulfillmentOptions && boolAcc === null) {
      return false;
    }

    return !(product?.fulfillment?.fulfillmentOptions && !boolAcc);
  }, null);

  return hasFullfillmentOptions;
};

export const shouldDisableATCButton = ({
  products, quantityBelowLimit, productsFulfillmentInformation, disableATCForMissingProductsFlag, addToCartFlag
}) => {
  const productsFulfillmentStatus = getProductsMissingFulfillmentOptions({ products });
  const isBackordered = productsFulfillmentInformation?.cartingOptions?.backordered;

  const fulfillmentStatus = quantityBelowLimit && !productsFulfillmentStatus && !isBackordered;
  const isIncompleteBundle = hasMissingProducts(products) && disableATCForMissingProductsFlag;

  return !areProductsValid(products) || (addToCartFlag && !fulfillmentStatus) || isIncompleteBundle;
};

// Calculates the total price of the bundle from the original field
export const getOriginalPriceTotal = (products) => {
  return products?.map((product) => {
    return product.pricing?.original || product.pricing?.value;
  }).reduce((previousValue, currentValue) => {
    return previousValue + (currentValue || 0);
  }, 0);
};

const hasZeroPriceAndMapPolicy = (products) => {
  const productExists = products.find((product) => {
    return !product?.pricing?.value && !product?.pricing?.original && !!product?.pricing?.mapDetail;
  });
  return productExists;
};

const hasSpecialPriceViolation = (products) => {
  const specialPriceProduct = products?.find((product) => {
    return product?.pricing?.mapDetail?.mapPolicy === SHOW_SPECIAL_PRICE_IF_AUTH;
  });
  return specialPriceProduct;
};

const hasShowPriceInCartViolation = (products) => {
  const showPriceinCartProduct = products?.find((product) => {
    return product?.pricing?.mapDetail?.mapPolicy === SHOW_PRICE_IN_CART;
  });
  return showPriceinCartProduct;
};
// Checks whether or not any of the products in the package are affected by MAP pricing
export const mapPriceExistsInBundle = (products, isCustomerIdentified) => {

  const showSpecialPriceIfAuthProducts = hasSpecialPriceViolation(products);

  const showPriceinCart = hasShowPriceInCartViolation(products);

  const specialAndOriginalPriceViolationProduct = hasZeroPriceAndMapPolicy(products);

  const mapProductMapDetail = specialAndOriginalPriceViolationProduct
    || showPriceinCart || showSpecialPriceIfAuthProducts;

  const hidePrice = !!specialAndOriginalPriceViolationProduct;

  if (!mapProductMapDetail) {
    return {
      hidePrice: false,
      mapPolicy: null,
      minAdvertisedPrice: null,
      showMapInfo: false,
      showSignInMapAlert: false
    };
  }

  const { mapDetail } = mapProductMapDetail?.pricing ?? {};
  const { mapPolicy } = mapDetail ?? {};
  const showMapInfo = !!specialAndOriginalPriceViolationProduct
    || !!showPriceinCart || (!!showSpecialPriceIfAuthProducts && !isCustomerIdentified);

  return {
    hidePrice,
    mapPolicy,
    minAdvertisedPrice: !!mapPolicy,
    showMapInfo,
    showSignInMapAlert: !!showSpecialPriceIfAuthProducts && !isCustomerIdentified
  };
};

// Determines if the package qualifies for free shipping
export const hasMAFreeShipping = (price, applianceDeliveryThreshold) => {
  return price >= applianceDeliveryThreshold;
};

export const getMADeliveryFee = (
  products,
  enableApplianceDeliveryCharge = false,
  enableFreeDeliveryForExchange = false,
  applianceDeliveryChargeValue = 29,
  isExchangeCustomer = false,
  applianceDeliveryThreshold = 396
) => {
  const originalPriceTotal = getOriginalPriceTotal(products);
  const freeShipping = hasMAFreeShipping(originalPriceTotal, applianceDeliveryThreshold)
    || (isExchangeCustomer && enableFreeDeliveryForExchange);
  const showFlatDeliveryFee = !isExchangeCustomer && enableApplianceDeliveryCharge;
  const deliveryFee = showFlatDeliveryFee ? applianceDeliveryChargeValue : 0;

  return {
    deliveryFee,
    freeShipping
  };
};

export const getProductsDefaultValue = (products, priceUnitOfMeasure) => {
  let value = 0;
  let original = 0;

  products?.forEach((product) => {
    value += product?.pricing?.value || product?.pricing?.original || 0;
    original += product?.pricing?.original || 0;
  });

  return {
    value,
    original,
    unitOfMeasure: priceUnitOfMeasure
  };
};

export const getSetUnavailableMessage = ({ errorMessage, products }) => {
  const productInventories = getProductsFulfillmentInventories({ products });
  const isFulfillmentAvailable = getIsFulfillmentAvailable({ productInventories });
  const isError = errorMessage?.includes(SKU_NOT_VALID);

  return !!(isError && !isFulfillmentAvailable);
};

export const getBundleFulfillment = ({ products }) => {
  const fulfillment = products?.every((product) => {
    return !!((product?.fulfillment?.fulfillmentOptions || []).length);
  });
  if (fulfillment) {
    return products[0]?.fulfillment;
  }
  return [];
};

const getDeliveryType = (method) => {
  if (method === FULFILLMENT_METHODS.STH) {
    return 'sth';
  }
  if (method === FULFILLMENT_METHODS.BOPIS) {
    return 'bopis';
  }
  if (method === FULFILLMENT_METHODS.BOSS) {
    return 'boss';
  }
  if (method === FULFILLMENT_METHODS.BODFS) {
    return 'bodfs';
  }
  return '';
};

export const getPaypalCartItems = ({ cartItems, deliveryZip }) => {
  const pickup = [];
  const delivery = [];
  cartItems?.forEach((cartItem) => {
    const isDelivery = cartItem?.fulfillmentMethod === FULFILLMENT_METHODS.STH
      || cartItem?.filfillmentMethod === FULFILLMENT_METHODS.BODFS;
    const item = {
      itemId: cartItem?.itemId,
      quantity: cartItem?.quantity?.toString(),
      location: isDelivery ? deliveryZip : cartItem?.storeId,
      type: getDeliveryType(cartItem?.fulfillmentMethod)
    };
    isDelivery ? delivery.push(item) : pickup.push(item);
  });
  return { pickup, delivery };
};

export const formatBundles = (bundles) => bundles?.replace(/,\s*$/, '').split(',');
// useful when testing individual component or non bundle items
export const getBundlesFromUrlParam = () => {
  const urlParams = new URLSearchParams(window?.location?.search);
  const bundleProductIds = formatBundles(urlParams.get('bundles') || null);

  return bundleProductIds;
};

export const getProductGroupName = (product) => {
  if (product?.removed && product?.taxonomy?.breadCrumbs) {
    const index = product?.taxonomy?.breadCrumbs?.length - 2;
    return index > -1 ? product?.taxonomy?.breadCrumbs[index]?.label?.replace(/.$/, '') : 'Product';
  }
  return 'Product';
};

export const filterRemovedProducts = (products) => {
  if (!products) return [];
  const filteredProducts = getActiveProducts(products);
  return filteredProducts.filter((product) => !product?.removed);
};

export const getPriceAdjustment = async ({ products, storeId, url }) => {
  const responseGetPrice = await getPrice({
    cartRequestInfo: getCartRequestInfo({ products, storeId }),
    priceAdjustmentUrl: url
  });
  const adjustedProductsPricing = adjustProductsPricing(
    products,
    responseGetPrice?.cartResponse?.items
  );

  return adjustedProductsPricing;
};

export const isReturnMessagesEligible = (products) => {
  return products?.some((product) => (product?.info?.isInStoreReturnMessageEligible
    && product?.info?.returnable !== null) || false);
};

/**
 * Get Bundle Quantity
 * @param {{removed: boolean, itemId: string}[]} products - Bundle Quantity
*/
export const getBundleQuantity = (products = []) => {
  if (!areProductsValid(products)) return 0;

  const filterDiscontinued = getActiveProducts(products);
  return filterRemovedProducts(filterDiscontinued).reduce((counter, product) => counter + (product?.quantity || 1), 0);
};

export const getBundleDrawerRootProps = (isMobile) => {
  if (isMobile) return {};
  return {
    style: { maxWidth: 510, justifySelf: 'flex-end' }
  };
};

export const triggerLifeCycleEvent = (eventName, data = null) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(eventName, data);
};

export const onLifeCycleEvent = (eventName, data = null) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on(eventName, data);
};

export const offLifeCycleEvent = (eventName) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.off(eventName);
};

export const getBundleQuantityLimit = (bundleMaxQuantityLimit) => {
  if (bundleMaxQuantityLimit?.quantityLimit) return bundleMaxQuantityLimit?.quantityLimit;
  return bundleMaxQuantityLimit?.quantityLimitWithInventory ? bundleMaxQuantityLimit?.quantityLimitWithInventory : 0;
};

export const getMissingProductToasterFlavors = (featureKey) => {
  switch (featureKey) {
  case KITCHEN_INCOMPLETE_TOASTER_KEY:
    return {
      message: `One or more items is unavailable in this package. 
    You can add the remaining items to cart or shop other kitchen packages.`,
      anchorText: 'Shop Kitchen Appliance Packages'
    };
  case LAUNDRY_INCOMPLETE_TOASTER_KEY:
    return {
      message: `One or more items is unavailable in this package. 
    You can add the remaining items to cart or shop other laundry packages.`,
      anchorText: 'Shop Laundry Appliance Packages'
    };
  case GM_COMBO_INCOMPLETE_TOASTER_KEY:
    return {
      message: 'One or more items is unavailable in this package.',
      anchorText: 'Shop other bundles'
    };
  default:
    return {
      message: 'One or more items is unavailable in this package.',
      anchorText: ''
    };
  }
};