import {
  BUNNDLE_TYPE_COMBO,
  EXPERIENCE_TYPE_GM,
  BUNNDLE_TYPE_PACKAGE,
  EXPERIENCE_TYPE_KITCHEN,
  EXPERIENCE_TYPE_LAUNDRY
} from './base-configuration';

export const COMBO_CONFIGURATION = {
  quickView: false,
  productListModelNumber: false,
  productListRating: false,
  productListViewSpecificationLink: true,
  allowSwap: false,
  allowRemove: false,
  productLabelScrollableLink: true,
  brandNameScrollableLink: true
};

export const PACKAGE_CONFIGURATION = {
  quickView: true,
  productListModelNumber: true,
  productListRating: true,
  productListViewSpecificationLink: false,
  allowSwap: true,
  allowRemove: true,
  productLabelScrollableLink: false,
  brandNameScrollableLink: false
};

export const KITCHEN_PACKAGE = {
  ...PACKAGE_CONFIGURATION,
  productListFuelType: true,
  directDeliveryCartMethod: true,
  showVarientDrawerInfoIcon: false
};

export const LAUNDRY_PACKAGE = {
  ...PACKAGE_CONFIGURATION,
  showVarientDrawerInfoIcon: true,
  productListViewSpecificationLink: true,
  allowSwap: false,
  allowRemove: false
};

export const GM_COMBO = {
  ...COMBO_CONFIGURATION,
  showVarientDrawerInfoIcon: false
};

export const GM_PACKAGE = {
  ...PACKAGE_CONFIGURATION,
  showVarientDrawerInfoIcon: false,
  productListViewSpecificationLink: true,
  allowSwap: true,
  allowRemove: true,
  productLabelScrollableLink: true,
  brandNameScrollableLink: true,
  quickView: false
};

export const PRODUCTS_CONFIGURATION = {
  [BUNNDLE_TYPE_COMBO]: {
    [EXPERIENCE_TYPE_GM]: GM_COMBO
  },
  [BUNNDLE_TYPE_PACKAGE]: {
    [EXPERIENCE_TYPE_KITCHEN]: KITCHEN_PACKAGE,
    [EXPERIENCE_TYPE_LAUNDRY]: LAUNDRY_PACKAGE,
    [EXPERIENCE_TYPE_GM]: GM_PACKAGE
  }
};