import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PaintSwatches } from '@one-thd/sui-icons';

const ProductImage = ({
  colorType, rgb, brandLogo, configuredAssetUrl, image
}) => {
  if (colorType && colorType !== 'CustomColor' && rgb) {
    return (
      <div
        className="sui-aspect-square sui-flex sui-items-end"
        style={{
          backgroundColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
        }}
      >
        <img
          src={brandLogo}
          alt="Brand"
          className="sui-w-full sui-aspect-square"
          height="1"
          width="1"
        />
      </div>
    );
  }
  if (colorType === 'CustomColor' || !rgb) {
    return (
      <div
        className={classNames('sui-bg-inactive',
          'sui-flex',
          'sui-items-center',
          'sui-justify-center',
          'sui-w-full',
          'sui-h-full'
        )}
      >
        <div
          className="sui-text-center sui-flex sui-items-center sui-justify-center"
        >
          <PaintSwatches color="medium" size="large" />
        </div>
      </div>
    );
  }
  return (
    <img
      src={configuredAssetUrl || image?.url.replace(/_1000./, '_100.')}
      alt="Product"
      className="sui-w-full sui-aspect-square"
      height="1"
      width="1"
    />
  );
};

ProductImage.propTypes = {
  colorType: PropTypes.string,
  rgb: PropTypes.shape({
    red: PropTypes.number,
    green: PropTypes.number,
    blue: PropTypes.number,
  }),
  brandLogo: PropTypes.string,
  configuredAssetUrl: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
};

ProductImage.defaultProps = {
  colorType: '',
  rgb: null,
  brandLogo: '',
  configuredAssetUrl: '',
  image: null,
};

export default ProductImage;