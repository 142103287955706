export const DRYER_FUEL_TYPE = 'Dryer Fuel Type';
export const LAUNDRY_ACCESSORIES_DRAWER = 'Laundry Accessories';
export const WASHER_TYPE = 'Agitator/Impeller Type';
export const KITCHEN_FUEL_TYPE = 'Fuel Type';
export const APPLIANCE_TYPE = 'Appliance Type';

export const WASHER = 'Washer';
export const DRYER = 'Dryer';
export const IMPELLER = 'Impeller';
export const AGITATORS = 'Agitator';
export const GAS = 'Gas';
export const ELECTRIC = 'Electric';