/* eslint-disable max-len */
import React from 'react';
import { shape, bool, number } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { formatPrice } from '../../utils/product-bundle-utils';
import { TEXT } from '../../constants';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {object} deliveryData Delivery Fee and a flag to indicate if free shipping
 */

/**
 * Returns The Delivery Fee and Tax Line Items based on several conditions.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const MajorApplianceDeliveryAndTaxContent = ({ deliveryData: { deliveryFee, freeShipping } }) => {

  let totalText;
  if (freeShipping) {
    totalText = TEXT.FREE;
  }
  if (deliveryFee) {
    totalText = formatPrice(deliveryFee);
  }

  const suiRowClasses = 'sui-flex sui-flex-row sui-justify-between sui-mt-2';
  const dashClsss = 'sui-w-3 sui-bg-primary sui-border-solid sui-border-strongest sui-border-b-1 sui-mr-5 sui-ml-auto sui-h-4';

  return (
    <div data-component="MajorApplianceDeliveryAndTaxContent" data-testid="major-appliance-delivery-and-tax-content">
      {(freeShipping || deliveryFee) ? (
        <div className={suiRowClasses}>
          <Typography variant="body-base">Appliance Delivery</Typography>
          <Typography variant="body-base" color={freeShipping && !deliveryFee ? 'success' : ''}>
            {totalText}
          </Typography>
        </div>
      ) : (
        <div className={`${suiRowClasses} sui-items-center`}>
          <Typography variant="body-base">
            Appliance Delivery calculated in cart
          </Typography>
          <div className="sui-flex sui-flex-col sui-items-center sui-h-1">
            <span className={dashClsss} />
          </div>
        </div>
      )}
      <div className={`${suiRowClasses} sui-items-center`}>
        <Typography variant="body-base">Taxes calculated at checkout</Typography>
        <div className="sui-flex sui-flex-col sui-items-center sui-h-1">
          <span className={dashClsss} />
        </div>
      </div>
    </div>
  );
};

MajorApplianceDeliveryAndTaxContent.propTypes = {
  deliveryData: shape({
    freeShipping: bool,
    deliveryFee: number
  }).isRequired
};

MajorApplianceDeliveryAndTaxContent.displayName = 'MajorApplianceDeliveryAndTaxContent';

export { MajorApplianceDeliveryAndTaxContent };
