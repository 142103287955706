import React from 'react';
import { useNavigate } from '@thd-olt-component-react/router';
import { Typography, Alert, Link } from '@one-thd/sui-atomic-components';
import PropTypes from 'prop-types';
import {
  getMissingProductToasterFlavors
} from '../../utils/product-bundle-utils';

export const MissingProductToaster = ({
  onClose,
  product
}) => {
  const {
    missingProductToasterKey
  } = product?.features || {};

  const { breadCrumbs } = product?.taxonomy || [];
  const navigate = useNavigate();
  const mostRecentBreadcrumb = breadCrumbs?.at(-1)?.url || '';
  const { message, anchorText } = getMissingProductToasterFlavors(missingProductToasterKey);

  const navigateToPackage = (breadcrumb) => {
    navigate(breadcrumb);
  };

  return (
    <Alert
      status="warning"
      data-testid="missing-product-toaster"
      className="sui-border-1 sui-mt-4 sui-border-solid sui-border-strong sui-rounded-base"
      onClose={onClose}
    >
      <div className="sui-flex sui-gap-4 sui-w-full">
        <div className="sui-flex-1">
          <div className="sui-mb-2">
            <Typography variant="body-base">
              {message}
            </Typography>
          </div>
          {mostRecentBreadcrumb && !!anchorText && (
            <Link
              component="button"
              onClick={() => navigateToPackage(mostRecentBreadcrumb)}
            >
              {anchorText}
            </Link>
          )}
        </div>
      </div>
    </Alert>
  );
};

MissingProductToaster.defaultProps = {
  product: {
    taxonomy: {
      breadcrumbs: [{ url: '' }]
    }
  }
};
MissingProductToaster.propTypes = {
  onClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    features: PropTypes.shape({
      missingProductToasterKey: PropTypes.string
    }),
    taxonomy: PropTypes.shape({
      breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string
        })
      )
    })
  })
};