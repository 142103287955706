import { useMemo } from 'react';

export const useVariantType = ({ product, variants, onChange }) => {
  const value = product?.itemId;

  const renderComponent = variants?.length > 0;

  const items = useMemo(() => {
    return variants?.map((variant) => ({
      value: variant?.id,
      name: variant?.value
    })) || [];
  }, [variants]);

  const handleChange = (newId) => onChange(value, newId, true);

  return {
    value,
    items,
    renderComponent,
    handleChange
  };
};