export const OUT_OF_STOCK = 'oos';
export const NOT_AVAILABLE = 'not_available';

export const CART_BUNDLE_TYPE = 'BUNDLE';

export const ITEM_PROMO = 'ITEMPROMO';
export const ORDER_PROMO = 'ORDERPROMO';

export const SHOW_SPECIAL_PRICE_IF_AUTH = 'showSpecialPriceIfAuth';
export const SHOW_PRICE_IN_CART = 'showPriceInCart';

export const KITCHEN_INCOMPLETE_TOASTER_KEY = 'KITCHEN_TOASTER';
export const LAUNDRY_INCOMPLETE_TOASTER_KEY = 'LAUNDRY_TOASTER';
export const GM_COMBO_INCOMPLETE_TOASTER_KEY = 'COMBO_TOASTER';
