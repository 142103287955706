/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { string, func, arrayOf, shape } from 'prop-types';
import { Tile, TileGroup, Typography } from '@one-thd/sui-atomic-components';

import { useVariantType } from '../../../hooks/useVariantType';

/**
 * SelectableVariantTile component renders a group of selectable tiles based on the provided variants.
 * It uses the useVariantType hook to manage the state and behavior of the tile selection.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.product - The product object
 * @param {string} props.product.itemId - The ID of the product item
 * @param {Array} props.variants - The array of variant objects
 * @param {string} props.variants[].id - The ID of the variant
 * @param {string} props.variants[].value - The value of the variant
 * @param {Function} props.onChange - The callback function to handle change events
 * @returns {JSX.Element|null} The rendered component or null if renderComponent is false
 */

export const SelectableVariantTile = ({ product, variants, onChange }) => {
  const { items, value, renderComponent, handleChange } = useVariantType({ product, variants, onChange });

  if (!renderComponent) {
    return null;
  }

  return (
    <div className="[&>*]:sui-gap-2 [&_button]:sui-p-2" data-testid="selectable-variant-tile">
      <TileGroup
        value={value}
        onChange={(__, newValue) => handleChange(newValue)}
      >
        {
          items?.map((item) => (
            <Tile
              key={item?.value}
              value={item?.value}
            >
              <Typography variant="body-base" height="tight">{item?.name}</Typography>
            </Tile>
          ))
        }
      </TileGroup>
    </div>
  );
};

SelectableVariantTile.propTypes = {
  variants: arrayOf(
    shape({
      id: string.isRequired,
      value: string.isRequired
    })
  ),
  product: shape({
    itemId: string
  }),
  onChange: func
};

SelectableVariantTile.defaultProps = {
  variants: null,
  product: null,
  onChange: () => { }
};
SelectableVariantTile.displayName = 'SelectableVariantTile';