import React from 'react';
import { string, func, arrayOf, shape } from 'prop-types';
import { RadioGroup, Radio, SelectionControlLabel } from '@one-thd/sui-atomic-components';

import { useVariantType } from '../../../hooks/useVariantType';

/**
 * SelectableVariantRadio component renders a group of radio buttons for selecting a variant.
 * It uses the useVariantType hook to manage the state and behavior of the radio button selection.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.product - The product object.
 * @param {string} props.product.itemId - The ID of the product item.
 * @param {Array} props.variants - The array of variant objects.
 * @param {string} props.variants[].id - The ID of the variant.
 * @param {string} props.variants[].value - The value of the variant.
 * @param {Function} props.onChange - The callback function to handle change events.
 *
 * @returns {JSX.Element|null} The rendered component or null if renderComponent is false.
 */
export const SelectableVariantRadio = ({ product, variants, onChange }) => {
  const { items, value, renderComponent, handleChange } = useVariantType({ product, variants, onChange });

  if (!renderComponent) {
    return null;
  }

  return (
    <div className="sui-flex sui-gap-1" data-testid="selectable-variant-radio">
      <RadioGroup
        aria-labelledby="bundle-selectable-variants"
        value={value}
        name="bundle-selectable-variants"
        onChange={(event) => handleChange(event?.target?.value)}
      >
        {items?.map((item) => (
          <SelectionControlLabel key={item?.value} value={item?.value} label={item?.name}>
            <Radio />
          </SelectionControlLabel>
        ))}
      </RadioGroup>
    </div>
  );
};

SelectableVariantRadio.propTypes = {
  variants: arrayOf(
    shape({
      id: string.isRequired,
      value: string.isRequired
    })
  ),
  product: shape({
    itemId: string
  }),
  onChange: func
};

SelectableVariantRadio.defaultProps = {
  variants: null,
  product: null,
  onChange: () => { }
};

SelectableVariantRadio.displayName = 'SelectableVariantRadio';