/**
 * Bundle types
 */
export const BUNNDLE_TYPE_COMBO = 'combo';
export const BUNNDLE_TYPE_PACKAGE = 'package';

/**
 * Experience types
 */
export const EXPERIENCE_TYPE_GM = 'general-merchandise';
export const EXPERIENCE_TYPE_KITCHEN = 'kitchen';
export const EXPERIENCE_TYPE_LAUNDRY = 'laundry';