import {
  BUNNDLE_TYPE_COMBO,
  EXPERIENCE_TYPE_GM,
  BUNNDLE_TYPE_PACKAGE,
  EXPERIENCE_TYPE_KITCHEN,
  EXPERIENCE_TYPE_LAUNDRY
} from './base-configuration';

import {
  KITCHEN_INCOMPLETE_TOASTER_KEY,
  LAUNDRY_INCOMPLETE_TOASTER_KEY,
  GM_COMBO_INCOMPLETE_TOASTER_KEY
} from './codes';

import {
  DRYER_FUEL_TYPE,
  WASHER_TYPE,
  KITCHEN_FUEL_TYPE
} from './variants';

const getRatingsAndReview = (enable = true) => ({
  questionsAndAnswers: enable,
  ratingsAndReview: enable,
  starRatingProductOverview: enable,
  starRatingStickyNav: enable,
  questionStickyNav: enable,
  starRatingStickyNavTitle: enable
});

const COMBO_CONFIGURATION = {
  ...getRatingsAndReview(),
  whatWeOffer: false,
  quantity: true,
  paypalCheckout: true,
  addToCart: true,
  salientPoint: true,
  swapToaster: false,
  dynamicImageGrid: false,
  deliveryInfo: false,
  deliveryExpress: true,
  deliveryAndTax: false,
  summaryRegularPriceType: 'items',
  calculateDeliveryFee: false,
  showBundleSavings: false,
  showUnavailableMessage: true,
  displayMissingProductsToaster: true,
  disableATCForMissingProducts: true,
  showQuantityLimit: true
};

const PACKAGE_CONFIGURATION = {
  ...getRatingsAndReview(false),
  whatWeOffer: true,
  quantity: false,
  paypalCheckout: false,
  addToCart: false,
  salientPoint: false,
  swapToaster: true,
  dynamicImageGrid: true,
  deliveryInfo: true,
  deliveryExpress: false,
  summaryRegularPriceType: 'appliances',
  showUnavailableMessage: false,
  disableATCForMissingProducts: false,
  showQuantityLimit: false
};
const GM_COMBO = {
  ...COMBO_CONFIGURATION,
  productType: 'MERCHANDISE',
  missingProductToasterKey: GM_COMBO_INCOMPLETE_TOASTER_KEY,
  productListTitle: 'Items in this bundle',
  priceUnitOfMeasure: 'bundle',
  bundleSummaryTitle: 'Bundle Summary'
};

const LAUNDRY_PACKAGE = {
  ...PACKAGE_CONFIGURATION,
  ...getRatingsAndReview(),
  salientPoint: true,
  swapToaster: false,
  dynamicImageGrid: false,
  priceUnitOfMeasure: null,
  bundleSummaryTitle: 'Summary',
  productType: 'MERCHANDISE',
  productListTitle: 'Appliances in this set',
  deliveryAndTax: false,
  calculateDeliveryFee: false,
  showBundleSavings: false,
  templates: {
    [DRYER_FUEL_TYPE]: 'SelectableVariantTile',
    [WASHER_TYPE]: 'SelectableVariantTile'
  },
  displayMissingProductsToaster: false,
  missingProductToasterKey: LAUNDRY_INCOMPLETE_TOASTER_KEY
};

const KITCHEN_PACKAGE = {
  ...PACKAGE_CONFIGURATION,
  bundleSummaryTitle: 'Package Summary',
  productType: 'MAJOR_APPLIANCE',
  priceUnitOfMeasure: 'package',
  productListTitle: 'Appliances in this package',
  deliveryAndTax: true,
  calculateDeliveryFee: true,
  showBundleSavings: true,
  templates: {
    [KITCHEN_FUEL_TYPE]: 'SelectableVariantRadio'
  },
  displayMissingProductsToaster: true,
  missingProductToasterKey: KITCHEN_INCOMPLETE_TOASTER_KEY
};

const GM_PACKAGE = {
  ...PACKAGE_CONFIGURATION,
  ...getRatingsAndReview(),
  showQuantityLimit: true,
  salientPoint: true,
  swapToaster: true,
  dynamicImageGrid: false,
  priceUnitOfMeasure: 'bundle',
  bundleSummaryTitle: 'Bundle Summary',
  productType: 'MERCHANDISE',
  productListTitle: 'Items in this bundle',
  deliveryAndTax: false,
  calculateDeliveryFee: false,
  showBundleSavings: false,
  displayMissingProductsToaster: false,
  missingProductToasterKey: GM_COMBO_INCOMPLETE_TOASTER_KEY,
  deliveryInfo: false,
  whatWeOffer: false,
  quantity: true,
  paypalCheckout: true
};

export const BUNDLE_CONFIGURATION = {
  [BUNNDLE_TYPE_COMBO]: {
    [EXPERIENCE_TYPE_GM]: GM_COMBO
  },
  [BUNNDLE_TYPE_PACKAGE]: {
    [EXPERIENCE_TYPE_KITCHEN]: KITCHEN_PACKAGE,
    [EXPERIENCE_TYPE_LAUNDRY]: LAUNDRY_PACKAGE,
    [EXPERIENCE_TYPE_GM]: GM_PACKAGE
  }
};