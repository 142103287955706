/* eslint-disable max-len */
/* eslint-disable tailwindcss/no-arbitrary-value */

import React, { useContext } from 'react';
import { Add } from '@one-thd/sui-icons';
import { bool, shape, func, node } from 'prop-types';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { SwapItem } from '@thd-olt-component-react/swap-item';
import { Typography } from '@one-thd/sui-atomic-components';
import { SwapProductLoader } from './SwapProductLoader';
import { getBundleDrawerRootProps, getProductGroupName } from '../../utils/product-bundle-utils';

const Container = ({ children }) => (
  <div
    className="sui-relative sui-flex sui-items-center sui-w-full sui-gap-4 hover:sui-cursor-pointer empty-container"
    data-testid="empty-container"
    data-component="EmptyContainer"
  >
    {children}
  </div>
);

Container.propTypes = {
  children: node.isRequired
};

export const EmptyContainer = ({
  product, loading, onClick, setSelectedItemId, isMobile
}) => {
  const { defaultVariables } = useContext(QueryContext) || {};

  if (product?.isDiscontinued) return null;

  if (loading) {
    return (
      <Container>
        <SwapProductLoader />
      </Container>
    );
  }

  return (
    <QueryProvider
      cacheKey="bundle-products-swap-item"
      defaultVariables={defaultVariables?.current}
    >
      <SwapItem
        className="sui-w-full z-[2000]"
        itemId={product.itemId}
        onSwap={(swappedItem) => {
          if (swappedItem) {
            setSelectedItemId(product.itemId);
            onClick(product.itemId, swappedItem?.product?.itemId);
          }
        }}
        hideQuickViewMask
        openInQuickViewDescription
        openDetailsInNewTab
        openCurrentItemInQuickView
        currentItemSelectable
        closeOnSwap
        drawerRootProps={getBundleDrawerRootProps(isMobile)}
        customElement={(
          <Container>
            <div className="sui-border-dashed sui-border-1 sui-border-strong sui-p-5 [&>*]:sui-block">
              <Add data-testid="AddIcon" size="regular" />
            </div>
            <Typography variant="body-base" weight="bold">Add {getProductGroupName(product)}</Typography>
          </Container>
        )}
      />
    </QueryProvider>
  );
};

EmptyContainer.propTypes = {
  product: shape({}).isRequired,
  loading: bool.isRequired,
  onClick: func.isRequired,
  setSelectedItemId: func.isRequired,
  isMobile: bool
};

EmptyContainer.defaultProps = {
  isMobile: false
};