import React from 'react';
import { string, bool, shape } from 'prop-types';

import { extend } from '@thd-nucleus/data-sources';
import { ConsumerCardMessaging } from '@thd-olt-component-react/consumer-card-messaging';

import { getCustomerType } from '../utils/product-bundle-utils';
import { getBundleWithPricing } from '../utils/bundle-price-utils';

/**
 * Component for rendering messaging related to a bundle consumer card.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.product - The product data, including features and other details.
 * @param {Object} props.clsRemediation - The CLS remediation data.
 * @param {Object} props.pricingDetails - The pricing details for the product.
 * @param {boolean} props.pricingDetails.loading - Indicates if the pricing details are still loading.
 * @param {Object} props.pricingDetails.pricing - The pricing information for the product.
 *
 * @returns {JSX.Element|null} The rendered component or null if pricing details are loading.
 */
const BundleConsumerCardMessaging = ({
  product,
  clsRemediation,
  pricingDetails
}) => {
  const { productType } = product?.features ?? {};

  if (pricingDetails?.loading) {
    return null;
  }

  const bundleWithPricing = getBundleWithPricing({ bundle: product, pricing: pricingDetails?.pricing });
  const productData = { ...bundleWithPricing, identifiers: { ...bundleWithPricing, productType } };

  return (
    <div data-component="BundleConsumerCardMessaging" data-testid="bundle-consumer-card-messaging">
      <ConsumerCardMessaging
        product={productData}
        isB2B={getCustomerType() === 'B2B'}
        clsRemediation={clsRemediation}
      />
    </div>
  );
};

BundleConsumerCardMessaging.propTypes = {
  product: shape({
    bundleSpecificationDetails: shape({
      type: string
    })
  }).isRequired,
  pricingDetails: shape({
    pricing: shape({}),
    loading: bool
  }),
  clsRemediation: shape({
    placeholders: bool,
    preservePlaceholders: bool
  })
};

BundleConsumerCardMessaging.defaultProps = {
  pricingDetails: null,
  clsRemediation: {
    placeholders: false,
    preservePlaceholders: false
  }
};

BundleConsumerCardMessaging.displayName = 'BundleConsumerCardMessaging';

BundleConsumerCardMessaging.dataModel = extend(ConsumerCardMessaging);

export { BundleConsumerCardMessaging };