/* eslint-disable max-len */
export default {
  header: 'Agitator or Impeller',
  info: [
    {
      title: 'Agitator',
      sections: [
        {
          text: 'Top load washers often have spindles or posts with attached vanes or fins. Placed in the middle of the wash basket, this agitator moves vigorously back and forth during the wash or rinse cycle. This movement makes its vanes or fins agitate or rub against fabrics to remove dirt and stains.'
        },
        {
          header: 'Benefits:',
          list: [
            'Can handle large loads',
            'Heavy-duty cleaning action',
            'Variety of fabric care options',
            'Budget friendly'
          ]
        }
      ]
    },
    {
      title: 'Impeller',
      sections: [
        {
          text: 'Impeller washing machines have low profile discs at the bottom of the drum that rotate. This circular motion creates a strong current of water that moves the clothes and removes stains and dirt from fabrics.'

        },
        {
          header: 'Benefits:',
          list: [
            'Uses less water and detergent',
            'Room for larger single loads',
            'Gentler washing action'
          ]
        }
      ]
    }
  ]
};
