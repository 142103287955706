import React, {
  useEffect, useCallback, useState
} from 'react';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { StickyWithHeaderObserver } from '@thd-olt-component-react/sticky';
import {
  RatingMeter,
  Typography,
  Button,
  Link as BrandLink
} from '@one-thd/sui-atomic-components';
import { ArrowUp } from '@one-thd/sui-icons';
import PropTypes from 'prop-types';
import {
  alias,
  arrayOf as arrayType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  extend
} from '@thd-nucleus/data-sources';

import {
  useReviewsDeprecated
} from '@thd-nucleus/data-sources/react/dataModel/migration';
import { Price } from '@thd-olt-component-react/price';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import helpers from './helpers';
import { Link, Links, SmoothScroll, ProductImage } from './components';
import { useStickyProduct } from '../hooks/useStickyProduct';
import { getProductPricing, getProductDetails } from '../utils/product';
import { getReviewStatistics } from '../utils/review';

const StickyNav = React.forwardRef((props, stickyNavRef) => {

  const {
    children,
    offset,
    itemId,
    product: productData,
    hideIfHDPPSku,
    hideSaveToFavorite,
    ratingsRef,
    onRatingsClick,
    configId,
    ratingsSSREnabled,
    hideStarRatings
  } = props;

  const [hideStickyNav, setHideStickyNav] = useState(true);

  const { isExchangeCustomer } = useThdCustomer() || {};

  const goToTop = useCallback(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return null;
    }
    const displayStickyNav = () => {
      const shouldHide = window.scrollY < offset;
      if (hideStickyNav !== shouldHide) {
        setHideStickyNav(shouldHide);
      }
    };
    window.addEventListener('scroll', displayStickyNav, { passive: true });
    return () => {
      window.removeEventListener('scroll', displayStickyNav);
    };
  }, [hideStickyNav]);

  useEffect(() => {
    const { onGetElement } = props;
    if (!helpers.ref && stickyNavRef.current) {
      helpers.setStickyNavRef(stickyNavRef);
      if (onGetElement) {
        onGetElement(stickyNavRef);
      }
    }

  }, [stickyNavRef?.current]);

  const reviewService = useReviewsDeprecated({ itemId, ssr: ratingsSSREnabled });
  const { totalResults, averageOverallRating } = getReviewStatistics(reviewService);

  const {
    configuredAssetUrl,
    configuredProductLabel
  } = useLifeCycleEventBus('configurator.configurator_sticky_nav_event');
  const { configuratorPricing } = useLifeCycleEventBus('configurator.configurator_pricing_event');
  const { product } = useStickyProduct({ productData, itemId, configId });

  const {
    identifiers: {
      brandName = '',
      productLabel = ''
    } = {},
    paintDetails: {
      colorType = '',
      colorDisplayName = '',
      brandLogo = '',
      rgb = {}
    } = {},
    pricing = {},
    reviews = {}
  } = product;

  const {
    brandLinkUrl, value, image, reviewCount, hasAverageRating
  } = getProductDetails(
    product, reviews, configuratorPricing, itemId, pricing, averageOverallRating, totalResults
  );

  const passRef = (child) => {
    return child?.type?.name === (<Links />).type.name ? React.cloneElement(child, { stickyNavRef }) : child;
  };

  return (
    <StickyWithHeaderObserver
      offsetScroll={offset}
      position="top"
      stickyByDefault
      className="sui-w-full sui-left-0"
    >
      <div
        id="sticky-nav"
        className="sui-bg-primary sui-shadow-lg sui-shadow-black print:sui-hidden"
        ref={stickyNavRef}
        data-section="sticky-nav"
        data-component="StickyNav"
      >
        <div className="sui-w-full sui-max-w-screen-2xl sui-mx-auto sui-my-0 sui-p-4 sui-pb-0">
          <div className="sui-flex sui-flex-row sui-gap-4 sui-pe-16 sui-relative">
            <div className="sui-w-20 sui-h-20 sui-min-w-20">
              <ProductImage
                colorType={colorType}
                rgb={rgb}
                brandLogo={brandLogo}
                configuredAssetUrl={configuredAssetUrl}
                image={image}
              />
            </div>
            <div className="sui-flex sui-flex-row sui-justify-between sui-gap-4 sui-w-full">
              <div
                className="sui-flex sui-flex-col sui-justify-between lg:sui-max-w-xl xl:sui-max-w-4xl"
                data-testid="sticky-nav__product_details"
              >
                <div>
                  <Typography variant="h4">{configuredProductLabel || productLabel}</Typography>
                  {colorDisplayName && (
                    <Typography color="subtle">{`Color: ${colorDisplayName}`}</Typography>
                  )}
                </div>
                <div className="sui-flex sui-flex-row sui-items-center sui-gap-4">
                  {(brandName && brandLinkUrl) && (
                    <BrandLink
                      variant="body-base"
                      href={brandLinkUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      translate="no"
                    >
                      {brandName}
                    </BrandLink>
                  )}
                  {!hideStarRatings
                    && (!(hideIfHDPPSku && product?.identifiers?.skuClassification === 'hdpp'))
                    && (
                      <button
                        type="button"
                        onClick={onRatingsClick}
                        data-testid="sticky-nav__ratings-reviews"
                        style={{ all: 'unset', cursor: 'pointer' }}
                      >
                        <SmoothScroll targetRef={ratingsRef}>
                          <RatingMeter
                            className="sui-p-0"
                            value={hasAverageRating}
                            label={`(${reviewCount})`}
                            RatingProps={{
                              color: 'brand'
                            }}
                          />
                        </SmoothScroll>
                      </button>
                    )}
                  {!hideSaveToFavorite && !isExchangeCustomer && (
                    <div data-testid="sticky-nav__favorite">
                      <AddToList itemId={itemId} showIconButton showCount />
                    </div>
                  )}
                </div>
              </div>
              <div
                data-testid={`sticky-nav__price-value--${value}`}
              >
                {value && (
                  <Price
                    itemId={itemId}
                    product={getProductPricing(product)}
                    channel="desktop"
                    type="simple"
                    displayEachUom={false}
                    clsRemediation={{
                      placeholders: true,
                      preservePlaceholders: true
                    }}
                  />
                )}
              </div>
            </div>
            <Button
              className="sui-bg-inverse hover:sui-bg-brand sui-absolute sui-top-0 sui-right-0
              sui-flex sui-flex-col sui-justify-center sui-items-center sui-w-11 sui-h-11"
              onClick={goToTop}
            >
              <ArrowUp size="small" color="inverse" />
              <Typography variant="body-xs" weight="bold" color="inverse">Top</Typography>
            </Button>
          </div>
          <div data-testid="sticky-nav__jump-links" className="lg:sui-ml-5 xl:sui-ml-24">
            {React.Children.map(children, passRef)}
          </div>
        </div>
      </div>
    </StickyWithHeaderObserver>
  );
});

StickyNav.displayName = 'StickyNav';

StickyNav.dataModel = extend({
  clientOnlyProduct: alias('product').params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    itemId: stringType(),
    identifiers: shapeType({
      brandName: stringType(),
      productLabel: stringType(),
      skuClassification: stringType()
    }),
    taxonomy: shapeType({
      brandLinkUrl: stringType(),
    }),
    paintDetails: params({ storeId: stringType(), configId: stringType() }).shape({
      colorDisplayName: stringType(),
      colorType: stringType(),
      brandLogo: stringType(),
      rgb: shapeType({
        red: stringType(),
        green: stringType(),
        blue: stringType(),
      })
    }).skip('skipPaintDetails', true),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client(),
      images: arrayType(shapeType({
        url: stringType(),
        sizes: arrayType(stringType()),
        type: stringType(),
        subType: stringType()
      }))
    }),
    pricing: params({ storeId: stringType() }).shape({
      value: numberType(),
      unitOfMeasure: stringType()
    }),
    reviews: shapeType({
      ratingsReviews: shapeType({
        averageRating: numberType(),
        totalReviews: numberType()
      })
    })
  }),
  reviews: params({ itemId: stringType().isRequired() }).shape({
    Includes: shapeType({
      Products: shapeType({
        items: arrayType(shapeType({
          FilteredReviewStatistics: shapeType({
            AverageOverallRating: stringType(),
            TotalReviewCount: numberType()
          })
        })),
        store: shapeType({
          FilteredReviewStatistics: shapeType({
            AverageOverallRating: stringType()
          })
        })
      })
    }),
    TotalResults: numberType()
  })
}, AddToList, Price);

StickyNav.propTypes = {
  children: PropTypes.node,
  itemId: PropTypes.string.isRequired,
  configId: PropTypes.string,
  offset: PropTypes.number,
  /** onComplete Function. */
  onGetElement: PropTypes.func,
  ratingsRef: PropTypes.shape({
    /* eslint-disable-next-line */
    current: PropTypes.any,
  }),
  onRatingsClick: PropTypes.func,
  hideIfHDPPSku: PropTypes.bool,
  hideSaveToFavorite: PropTypes.bool,
  ratingsSSREnabled: PropTypes.bool,
  hideStarRatings: PropTypes.bool,
  product: PropTypes.shape({})
};
StickyNav.defaultProps = {
  children: null,
  offset: 850,
  onGetElement: null,
  ratingsRef: null,
  onRatingsClick: null,
  hideIfHDPPSku: false,
  hideSaveToFavorite: false,
  configId: undefined,
  ratingsSSREnabled: false,
  hideStarRatings: false,
  product: null
};

StickyNav.Links = Links;
StickyNav.Link = Link;

export { StickyNav, Links, Link };