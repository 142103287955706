export const SKU_NOT_VALID = 'SkuNumbers are not valid';
export const MIN_ADVERTISED_PRICE_MESSAGE = `We've set an item's price lower than the manufacturer
 will let us advertise, so you'll have to add it to your cart to see our price. If you decide
 you don't want to purchase the item, you can remove it from your cart.`;
export const MIN_ADVERTISED_PRICE_MESSAGE_SIGN_IN = `The Home Depot price for one or more items is
 different from what the manufacturer allows us to advertise.`;
export const CUSTOM_PRICE_VSO = 'Custom - Starting Price & Virtual Assist Only';
export const TOOLTIP_VIEW_DETAILS = 'View Details';
export const TEXT = {
  FREE: 'Free',
  UNKNOWN: '-'
};