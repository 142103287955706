import React, {
  useEffect, useMemo, useContext, useCallback
} from 'react';
import {
  string, bool, number, shape, arrayOf
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import {
  ProductPod,
  ProductATC,
  ProductRating,
  PodRow,
  ProductBadge,
  ProductImage,
  PodFooter,
  PodSection,
  PodSpacer,
  PodTapper,
} from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import './SponsoredProductPod.scss';
import { newRelicConstants } from '../../constants/newRelicConstants';
import { dataModel } from './SponsoredProductPod.dataModel';
import { isSafari } from '../../utils/utils';
import { useSponsoredProductPodTelemetry, useProductPodRedirectUrl, useNodeRef } from '../../utils/hooks';

const SponsoredProductPod = ({
  product, storeId, position, noATCFulfillment, showAtc, impressionHeadline
}) => {
  const experienceContext = useContext(ExperienceContext);

  // nodes
  const [productPodNodeRef, productPodNode] = useNodeRef();
  const [productPodRatingRef, productPodRatingNode] = useNodeRef();

  // --
  const isMobile = useMemo(
    () => experienceContext.channel === 'mobile',
    [experienceContext.channel]
  );

  // --
  const { redirectUrl } = useProductPodRedirectUrl({
    canonicalUrl: product?.identifiers?.canonicalUrl
  });

  // -----------------
  // Telemetry
  // -----------------
  const {
    triggerClickOnAtcButton,
    triggerClickOnImage,
    triggerClickOnHeader,
    triggerClickOnRating,
    triggerClickOnPod,
  } = useSponsoredProductPodTelemetry({
    productPodNode,
    itemId: product?.itemId,
    trackSource: product?.info?.sponsoredMetadata?.trackSource,
    position,
    slotId: product?.info?.sponsoredMetadata?.slotId,
    podKpiName: newRelicConstants.CAROUSEL_PLA,
  });

  // -----------------
  // Handlers
  // -----------------
  /**
   * Handler on: atc 'click'
   */
  const handleClickOnProductAtc = useCallback(() => {
    triggerClickOnAtcButton(); // new-relic
  }, [triggerClickOnAtcButton]);

  /**
   * Handler on: image 'click'
   */
  const handleClickOnProductImage = useCallback((event) => {
    event.preventDefault();
    // --
    triggerClickOnImage(); // new-relic
    window.location = redirectUrl; // redirect
  }, [redirectUrl, triggerClickOnImage]);

  /**
   * Handler on: header 'click'
   */
  const handleClickOnProductHeader = useCallback((event) => {
    event.preventDefault();
    // --
    triggerClickOnHeader(); // new-relic
    window.location = redirectUrl; // redirect
  }, [redirectUrl, triggerClickOnHeader]);

  /**
   * Handler on: rating 'click'
   */
  useEffect(() => {
    // check
    if (!productPodRatingNode) return undefined; // case: not mounted
    if (!redirectUrl) return undefined;

    const selector = `a[href*="${product?.itemId}"]`;
    const element = productPodRatingNode.querySelector(selector);

    // check
    if (!element) return undefined;

    // --
    const handler = (event) => {
      event.preventDefault();
      // --
      triggerClickOnRating(); // new-relic
      window.location = redirectUrl; // redirect
    };

    // install
    element.addEventListener('click', handler);
    // uninstall
    return () => {
      element.removeEventListener('click', handler);
    };
  }, [productPodRatingNode, redirectUrl, product?.itemId, triggerClickOnRating]);
  // ---- ----

  /**
   * Handler on: pod 'click'
   */
  const handleClick = useCallback((event) => {
    event.preventDefault();
    // --

    // Note: This logic triggers the click event to analytics.
    //       Once this logic is placed in the product-pod, it should
    //       be removed from here to prevent duplicated clicks.
    const productPodEventData = {
      podAction: 'product pod',
      podAnchorSku: product?.itemId,
      target: '_self',
      parent: 'sponsored-carousel'
    };
    window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productPodEventData); // analytics: piq & adobe

    // --
    triggerClickOnPod(); // new-relic
    window.location = redirectUrl; // redirect
  }, [redirectUrl, triggerClickOnPod]);

  // -----------------
  // ProductPod
  // -----------------

  return (
    <div
      ref={productPodNodeRef}
      data-product-id={product.itemId}
      className="sponsored-product-pod"
      data-testid="sponsored-product-pod"
    >
      <ImpressionProvider
        data={{
          id: '',
          name: impressionHeadline || 'SponsoredProducts',
          component: 'SponsoredCarousel',
          type: 'sponsoredproduct',
          category: ''
        }}
      >
        <ProductPod
          itemId={product.itemId}
          storeId={storeId}
          padding="sui-p-2"
          analyticsData={{ parent: 'sponsored-carousel', position: position - 1 }}
          render={(pod) => (
            <>
              <a
                className="sui-top-0 sui-left-0 sui-absolute sui-size-full sui-z-1"
                href={redirectUrl}
                onClick={handleClick}
                aria-label="Link"
              />
              <PodRow noPadding>
                <ProductBadge itemId={pod.itemId} storeId={storeId} />
              </PodRow>
              <PodTapper zIndex>
                <ProductImage
                  itemId={pod.itemId}
                  showSecondaryImage={pod.showSecondaryImage}
                  onClick={handleClickOnProductImage}
                />
              </PodTapper>
              <PodSection columnAlign alignTop noPadding>
                <div data-testid={impressionHeadline} style={{ height: `${isSafari() ? '89px' : '68px'}` }}>
                  <PodTapper fitWidth zIndex>
                    <ProductHeader
                      brand="above"
                      itemId={pod.itemId}
                      brandTitleMaxLine={1}
                      titleMaxLine={2}
                      disableShopThisCollection
                      onClick={handleClickOnProductHeader}
                    />
                  </PodTapper>
                </div>
                <PodSpacer minHeight="26px">
                  <div ref={productPodRatingRef}>
                    <PodTapper fitWidth zIndex>
                      <ProductRating itemId={pod.itemId} />
                    </PodTapper>
                  </div>
                </PodSpacer>
                <PodTapper zIndex pointerNone aPointer buttonPointer spanPointer>
                  <PodSpacer minHeight="60px">
                    <Price
                      itemId={pod.itemId}
                      large={false}
                      storeId={storeId}
                      displayEachUom={false}
                      onSavingsCenterToggle={pod.onSavingsCenterToggle}
                      hideBadge
                      stackWasPrice
                    />
                  </PodSpacer>
                </PodTapper>
              </PodSection>
              {!isMobile && showAtc && (
                <PodFooter noPadding>
                  <PodSpacer minHeight="40px">
                    <PodTapper zIndex>
                      <ProductATC
                        itemId={pod.itemId}
                        storeId={storeId}
                        checkGeneric
                        outline
                        doNotShowChooseYourOptions
                        noATCFulfillment={noATCFulfillment}
                        onClick={handleClickOnProductAtc}
                      />
                    </PodTapper>
                  </PodSpacer>
                </PodFooter>
              )}
            </>
          )}
        />
      </ImpressionProvider>
    </div>
  );
};

SponsoredProductPod.propTypes = {
  storeId: string.isRequired,
  product: shape({
    itemId: string.isRequired,
    identifiers: shape({
      canonicalUrl: string,
    }),
    info: shape({
      sponsoredBeacon: shape({
        onClickBeacons: arrayOf(string),
        onViewBeacons: arrayOf(string),
      }),
      sponsoredMetadata: shape({
        trackSource: string,
        campaignId: string,
        placementId: string,
        slotId: string,
        sponsoredId: string,
      }),
    }),
  }).isRequired,
  position: number.isRequired,
  noATCFulfillment: bool,
  showAtc: bool,
  impressionHeadline: string.isRequired
};

SponsoredProductPod.defaultProps = {
  noATCFulfillment: null,
  showAtc: true,
};

SponsoredProductPod.dataModel = dataModel;

export { SponsoredProductPod };
