import React from 'react';
import { shape, string, bool } from 'prop-types';

import { extend } from '@thd-nucleus/data-sources';
import { Price } from '@thd-olt-component-react/price';

import { getBundleWithPricing } from '../utils/bundle-price-utils';
import { CustomSkeletonLine } from './subcomponents/CustomSkeletonLine';

/**
 * Component to display the price of a product bundle.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.itemId - The unique identifier for the item.
 * @param {Object} props.product - The product bundle object.
 * @param {Object} props.pricingDetails - The pricing details object.
 * @param {boolean} [props.pricingDetails.loading] - Indicates if the pricing details are still loading.
 * @param {Object} [props.pricingDetails.pricing] - The pricing information for the product bundle.
 * @returns {JSX.Element} The rendered price component or a skeleton loader if loading.
 */
export const BundlePrice = ({ itemId, product, pricingDetails }) => {

  if (pricingDetails?.loading) {
    return <CustomSkeletonLine height="48px" />;
  }

  return (
    <Price
      itemId={itemId}
      product={getBundleWithPricing({ bundle: product, pricing: pricingDetails?.pricing })}
    />
  );
};

BundlePrice.propTypes = {
  itemId: string.isRequired,
  product: shape({
    features: shape({ priceUnitOfMeasure: string.isRequired })
  }).isRequired,
  pricingDetails: shape({
    pricing: shape({}),
    loading: bool
  })
};

BundlePrice.defaultProps = {
  pricingDetails: null
};

BundlePrice.displayName = 'BundlePrice';

BundlePrice.dataModel = extend(Price);
