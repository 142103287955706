/* eslint-disable max-len */
export default {
  header: 'Pedestals & Stacking Kits',
  info: [
    {
      title: 'Pedestal',
      sections: [
        {
          text: 'Pedestals raise the height of front-load washers and dryers, making them easier to use and sometimes include a storage drawer. Pedestals cannot be used with stacking kits. Pricing refers to 2 pedestals.'
        }
      ]
    },
    {
      title: 'Stacking Kit',
      sections: [
        {
          text: 'Stacking kits save valuable floor space by securing front-load washers on top of front-load dryers with metal brackets. You cannot use a pedestals with stacking kits, as they do not support the weight.'
        }
      ]
    }
  ]
};
