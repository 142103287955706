import { useState, useEffect } from 'react';
import { offLifeCycleEvent, onLifeCycleEvent } from '../utils/product-bundle-utils';
import { PRODUCT_BUNDLE_PRODUCT_LIST_ITEM_CLICK } from '../constants/index';

const changeSelectedItem = (itemId) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(PRODUCT_BUNDLE_PRODUCT_LIST_ITEM_CLICK, { itemId });
};

const getDefaultSelectedProduct = (products) => {
  const filteredList = products?.filter((product) => !product?.removed);
  return filteredList?.[0]?.itemId;
};

const useSetSelectedItemId = ({ products }) => {
  const [selectedItemId, setSelectedItemId] = useState(getDefaultSelectedProduct(products));

  useEffect(() => {
    if (!selectedItemId && products?.length) {
      changeSelectedItem(getDefaultSelectedProduct(products));
      return;
    }

    const removedProduct = products?.find(
      (product) => product?.itemId === selectedItemId && product?.removed
    );
    if (removedProduct) {
      changeSelectedItem(getDefaultSelectedProduct(products));
      return;
    }

    const swapProduct = products?.find((product) => product?.swappedProduct?.itemId === selectedItemId);
    if (swapProduct) {
      changeSelectedItem(swapProduct.itemId);
    }
  }, [products, selectedItemId]);

  useEffect(() => {
    onLifeCycleEvent(PRODUCT_BUNDLE_PRODUCT_LIST_ITEM_CLICK, ({ output: product }) => {
      setSelectedItemId(product?.itemId);
    });
    return () => {
      offLifeCycleEvent(PRODUCT_BUNDLE_PRODUCT_LIST_ITEM_CLICK);
    };
  }, []);

  return {
    selectedItemId,
    changeSelectedItem
  };
};

export default useSetSelectedItemId;
