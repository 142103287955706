import { useStoreId } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';

export const useStickyProduct = ({ productData, itemId, configId }) => {
  const isValidProductData = productData && Object.keys(productData).length > 0;
  const storeId = useStoreId();
  const { data, error, loading } = useDataModel('clientOnlyProduct',
    {
      ssr: false,
      variables: {
        itemId,
        storeId,
        configId,
        skipPaintDetails: !configId
      },
      skip: storeId === '8119' || isValidProductData
    });

  return {
    renderComponent: isValidProductData || (data && !loading && !error),
    product: (isValidProductData ? productData : data?.product) || {},
  };
};
