import { useMemo } from 'react';

import {
  roundNumber,
  getMaxQuantityLimit,
  filterRemovedProducts,
  mapPriceExistsInBundle,
  getBundlePriceCalculations
} from '../utils/product-bundle-utils';
import useDeliveryData from './useDeliveryData';
import usePriceAdjustment from './usePriceAdjustment';
import { getBundlePricing } from '../utils/productBundleData';

export const useBundlePricingDetails = ({
  product, products, loading: productsLoading, isCustomerIdentified = false
}) => {
  const {
    priceUnitOfMeasure, calculateDeliveryFee, showQuantityLimit
  } = product?.features ?? {};

  const activeProducts = useMemo(() => filterRemovedProducts(products), [products]);
  const delivery = useDeliveryData(activeProducts, calculateDeliveryFee);
  const adjustments = usePriceAdjustment(activeProducts, productsLoading);
  const mapDetail = mapPriceExistsInBundle(activeProducts, isCustomerIdentified);
  const maxQuantityLimit = getMaxQuantityLimit(activeProducts);
  const calculations = getBundlePriceCalculations(adjustments || {}, (delivery?.deliveryFee || 0));

  const loading = !activeProducts?.length || productsLoading || !adjustments;

  const pricing = loading ? null : getBundlePricing(
    {
      originalPrice: adjustments?.bundleSummaryPrice,
      discount: calculations?.totalDollarOff,
      percent: calculations?.totalPercentOff,
      specialPrice: roundNumber({ number: calculations?.finalDiscountedPriceWithDeliveryFee, decimal: 2 }),
      mapDetail,
      quantityLimit: showQuantityLimit ? maxQuantityLimit?.quantityLimit : null,
      priceUnitOfMeasure
    }
  );

  return {
    loading,
    pricing,
    delivery,
    adjustments,
    mapDetail,
    calculations,
    maxQuantityLimit
  };
};