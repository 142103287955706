import { EXPERIENCE_TYPE_GM, BUNDLE_CONFIGURATION, PRODUCTS_CONFIGURATION } from '../constants/index';

import { getVarientName, getSelectableVariants } from './selectable-varient-utils';

const getExperienceType = (product) => {
  return product?.bundleSpecificationDetails?.experienceType || EXPERIENCE_TYPE_GM;
};

const getBundleType = (bundle) => bundle?.bundleSpecificationDetails?.type;

const getBrandName = (product, products) => {
  const { brand } = product?.bundleSpecificationDetails ?? {};
  if (brand) {
    return brand;
  }

  const { brandName: productBrandName } = product?.identifiers ?? {};
  if (productBrandName) {
    return productBrandName;
  }

  const { brandName: bundleBrandName } = products?.[0]?.identifiers ?? {};
  const showBrandName = products?.every((bundleProduct) => bundleProduct?.identifiers?.brandName === bundleBrandName);
  return showBrandName ? bundleBrandName : null;
};

const getBrandUrl = (product) => {
  const brandLinkUrl = product?.taxonomy?.brandLinkUrl;
  if (brandLinkUrl) {
    return brandLinkUrl;
  }
  const lastBreadCrumb = product?.taxonomy?.breadCrumbs?.length - 1;
  return product?.taxonomy?.breadCrumbs?.[lastBreadCrumb]?.url || null;
};

export const getProductFeatures = (bundleType, experienceType) => {
  if (!experienceType || !bundleType) {
    return {};
  }

  return BUNDLE_CONFIGURATION?.[bundleType]?.[experienceType] || {};
};

let staticSelectableVariants = {};
const getProductComponent = (bundleProduct, bundleProducts, experienceType) => {
  const updatedProductComponents = bundleProduct?.bundleSpecificationDetails?.components?.map((component, index) => {

    const id = component?.defaultProductId;
    if (!staticSelectableVariants[id]) {
      const varientName = getVarientName(experienceType, bundleProducts[index]);
      staticSelectableVariants[id] = {
        [varientName]: getSelectableVariants(
          component?.defaultProductId,
          component?.selectableVariants,
          bundleProducts[index],
          varientName
        )
      };
    }

    return {
      defaultProductId: component?.defaultProductId,
      allowSwap: component?.allowSwap,
      allowRemove: component?.allowRemove,
      quantity: component?.quantity,
      priority: component?.priority,
      selectableVariants: staticSelectableVariants[id]
    };
  });

  const sortedProductComponents = updatedProductComponents
    ?.sort((product1, product2) => product1.priority - product2.priority);

  return sortedProductComponents;
};

export const getUpdatedProduct = (bundleProduct, bundleProducts, configExperienceType, configBundleType) => {
  const experienceType = configExperienceType || getExperienceType(bundleProduct);
  const bundleType = configBundleType || getBundleType(bundleProduct);
  return {
    ...bundleProduct,
    features: {
      brand: getBrandName(bundleProduct, bundleProducts),
      brandUrl: getBrandUrl(bundleProduct),
      ...getProductFeatures(bundleType, experienceType)
    },
    productComponent: getProductComponent(bundleProduct, bundleProducts, experienceType)
  };
};

const getSwapAndRemoveFlag = (filteredProduct) => {
  if (!filteredProduct) {
    return {};
  }

  const { allowSwap, allowRemove } = filteredProduct || {};
  const swapRemoveObject = { allowSwap: !!allowSwap, allowRemove: !!allowRemove };
  return swapRemoveObject;
};

export const getProductsFeature = (filteredProduct, bundleType, experienceType) => {
  if (!bundleType || !experienceType) {
    return {};
  }

  const productConfiguration = PRODUCTS_CONFIGURATION?.[bundleType]?.[experienceType];

  if (!productConfiguration) {
    return {};
  }

  const swapAndRemoveFlag = getSwapAndRemoveFlag(filteredProduct);

  return {
    ...productConfiguration,
    ...swapAndRemoveFlag
  };
};

export const isMissingProducts = (bundleProductIds, bundleProducts) => {
  const hasMissingProducts = bundleProductIds?.length !== bundleProducts?.length
  || bundleProducts?.some((product) => product?.availabilityType?.discontinued);
  return hasMissingProducts;
};

export const getUpdatedProducts = (
  bundleProduct, bundleProducts, bundleProductIds, configExperienceType, configBundleType
) => {

  const experienceType = configExperienceType || getExperienceType(bundleProduct);
  const bundleType = configBundleType || getBundleType(bundleProduct);
  const hasMissingProducts = isMissingProducts(bundleProductIds, bundleProducts);
  const updatedProducts = bundleProducts?.map((product) => {
    const filteredProduct = bundleProduct?.productComponent?.find((component) =>
      component.defaultProductId === product.itemId);
    const isDiscontinued = !!product?.availabilityType?.discontinued;
    return {
      ...product,
      isIncomplete: hasMissingProducts || isDiscontinued,
      isDiscontinued,
      features: getProductsFeature(filteredProduct, bundleType, experienceType),
      quantity: filteredProduct?.quantity || 1,
      priority: filteredProduct?.priority ? Number(filteredProduct.priority) : 0
    };
  });

  const sortedProducts = updatedProducts.sort((product1, product2) => product1.priority - product2.priority);
  return sortedProducts;
};
