export const getReviewStatistics = (reviewService) => {
  const reviewData = reviewService.data?.reviews || {};
  const totalResults = reviewData?.TotalResults;
  const reviewProducts = reviewData?.Includes?.Products;
  const matchingReviewCount = reviewProducts?.items?.find((i) => {
    return i?.FilteredReviewStatistics?.TotalReviewCount === totalResults;
  });
  const averageOverallRating = matchingReviewCount?.FilteredReviewStatistics?.AverageOverallRating
    || reviewProducts?.store?.FilteredReviewStatistics?.AverageOverallRating;
  return { totalResults, averageOverallRating };
};