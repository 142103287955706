export const getPrice = async ({ cartRequestInfo, priceAdjustmentUrl = 'https://blinds.homedepot.com' }) => {
  try {
    const options = { method: 'POST',
      body: JSON.stringify(cartRequestInfo),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      } };

    const response = await fetch(
      `${priceAdjustmentUrl}/api/thd-bundle/priceadjustment`,
      options
    );

    if (response?.status === 200) {
      const data = await response.json();
      return data;
    }
    return 'Get Price: Request failed';
  } catch (err) {
    return 'Get Price: Request failed';
  }
};
