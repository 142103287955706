import React from 'react';
import { shape, string, arrayOf, func } from 'prop-types';
import { getTemplate } from '../templates';
import { isProductInVariantGroup } from '../../utils/selectable-varient-utils';

/**
 * Component that renders a layout for bundle templates.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.product - The product data.
 * @param {Object} props.templates - The templates for each group.
 * @param {Object} props.selectableVariants - The selectable variants for each group.
 * @param {Function} props.onChange - Callback function to handle changes.
 * @returns {JSX.Element|null} The rendered component or null if no selectable variants are available.
 */
export const BundleTemplateLayout = ({ product, templates, selectableVariants, onChange }) => {

  if (!selectableVariants || Object.keys(selectableVariants || {})?.length === 0) {
    return null;
  }

  return (
    <div className="sui-w-full sui-mt-2 md:sui-mt-4" data-testid="bundle-template-layout">
      {
        Object.keys(selectableVariants).map((group) => {
          const Template = getTemplate(templates?.[group]);
          if (!Template) {
            return null;
          }

          const variants = selectableVariants?.[group];

          if (!variants || !Array.isArray(variants) || variants?.length === 0) {
            return null;
          }

          if (!isProductInVariantGroup({ productId: product?.itemId, variants })) {
            return null;
          }

          return <Template key={group} product={product} variants={variants} onChange={onChange} />;
        })
      }
    </div>
  );
};

BundleTemplateLayout.propTypes = {
  selectableVariants: shape({
    [string]: arrayOf(
      shape({
        id: string.isRequired,
        value: string.isRequired
      })
    )
  }),
  templates: shape({
    [string]: string
  }).isRequired,
  product: shape({
    itemId: string
  }).isRequired,
  onChange: func.isRequired
};

BundleTemplateLayout.defaultProps = {
  selectableVariants: null
};

BundleTemplateLayout.displayName = 'BundleTemplateLayout';